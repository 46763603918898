import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable, { DataTableSortStatus, TableReportColumn } from "@impulso/common/components/report/DataTable";
import { Loader, LoadingOverlay, ScrollArea } from "@mantine/core";
import { addDays } from "date-fns/addDays";
import { format } from "date-fns/format";
import { useMemo, useState } from "react";
import { SortDirection } from "src/api/Models";
import { useGetCategoryPerformanceQuery } from "src/api/ReportApi";
import { useOrganisationId } from "src/common/security/UseGlobalSecurity";
import { DashFilter } from "src/pages/new/Dashboard";

export interface CategoryPerformance {
  category: string;
  sales: number;
}

export interface CategoryDrawerProps {
  startDate: Date;
  endDate: Date;
  sortDirection: SortDirection;
  filter: DashFilter;
}

export function CategoryDrawer(props: CategoryDrawerProps) {
  const organisationId = useOrganisationId();
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "sales",
    direction: props.sortDirection,
  });

  const {
    data,
    isFetching: isLoading,
    error: salesError,
  } = useGetCategoryPerformanceQuery({
    organisationId: organisationId!,
    startDate: format(props.startDate, "yyyy-MM-dd"),
    endDate: format(addDays(props.endDate, 1), "yyyy-MM-dd"),
    sortDirection: sortStatus.direction,
    filterQuery: props.filter.toQueryString(),
    limit: -1,
  });

  const columnData = useMemo(() => createColumns(), []);

  const categories = data?.categories.map(c => ({ category: c.category, sales: c.quantity }));

  if (salesError !== undefined) {
    return (
      <div className="w-full h-full flex items-center justify-center flex-col">
        <FontAwesomeIcon className="block w-16 h-16 text-brand" icon={faTriangleExclamation} />
        Failed to load data
      </div>
    );
  }
  return (
    <div className="h-full relative p-4">
      <ScrollArea className="overflow-auto h-full" offsetScrollbars>
        <DataTable
          records={categories}
          columns={columnData}
          sortStatus={sortStatus}
          onSortStatusChange={setSortStatus}
        />
      </ScrollArea>
      <LoadingOverlay
        visible={isLoading}
        loader={
          <div className="flex flex-col content-center items-center">
            <Loader />
          </div>
        }
      />
    </div>
  );
}

function createColumns(): TableReportColumn<CategoryPerformance>[] {
  return [
    {
      accessor: "category",
      title: "Category",
      sortable: false,
      visibility: "alwaysVisible",
    },
    {
      accessor: "sales",
      title: "Sales",
      sortable: true,
      visibility: "alwaysVisible",
    },
  ];
}
