import type { AnyAction, ThunkAction } from "@reduxjs/toolkit";
import { setupIntercomConfig } from "../common/intercom/Intercom";
import type { RootState } from "../store";
import { createSlice } from "@reduxjs/toolkit";

export function initApp(): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    if (getState().init.state !== null) {
      return; // Already running
    }

    console.log("Initiating app..");
    dispatch(slice.actions.setInitiated());

    await dispatch(setupIntercomConfig());

    console.log("Initiation complete");
    dispatch(slice.actions.setCompleted());
  };
}

interface InitSlice {
  state: null | "pending" | "completed";
}

const slice = createSlice({
  name: "init",
  initialState: { state: null } as InitSlice,
  reducers: {
    setInitiated: state => {
      state.state = "pending";
    },
    setCompleted: state => {
      state.state = "completed";
    },
  },
});

export default slice.reducer;
