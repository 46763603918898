import { Page } from "@impulso/common/components/Page";
import { useEffect, useState } from "react";
import { IFilterItem, generalFilter } from "@impulso/common/filters/generalFilter";
import { useOrganisation, useGlobalSecurity, useHasModule } from "../../common/security/UseGlobalSecurity";
import { useDebouncedValue } from "@mantine/hooks";
import { useGetDashboardSearchResultQuery, useGetIndividualStocksQuery } from "../../api/ReportApi";
import IndividualStocksReport, { IndividualStockOverview } from "src/modules/stock/IndividualStocksReport";
import { UserStorageItem, useGetUserQuery, useUpdateUserStorageMutation } from "src/api/UserApi";
import { SeasonSelectElement } from "src/common/components/dropdown/SeasonSelect";
import { downloadIndividualStockBreakdown } from "src/api/DownloadApi";

import useMobileDetect from "@impulso/common/hooks/useMobileDetect";
import { DashFilter, DashFilterKey } from "../new/Dashboard";
import SearchBar from "@impulso/common/components/searchBar/SearchBar";

const defaultFilterData: Record<DashFilterKey, IFilterItem[]> = {
  styleNames: [],
  styleNumbers: [],
  seasons: [],
  brands: [],
  departments: [],
  retailers: [],
  suppliers: [],
  stores: [],
  categories: [],
  eans: [],
  colors: [],
  sizes: [],
};

export default function IndividualStocks() {
  const organisation = useOrganisation();
  const hasAccess = useHasModule("impulso.product-tracker.view");
  const { data: userData, isLoading: loadingUser } = useGetUserQuery(undefined);
  const [userReadCount, setUserReadCount] = useState(0);
  const { accessToken } = useGlobalSecurity();
  const [UpdateUserStorage] = useUpdateUserStorageMutation();
  const [filter, setFilter] = useState(generalFilter(defaultFilterData));
  const [search, setSearch] = useState("");
  const [isFetchingExport, setIsFetchingExport] = useState(false);
  const [debouncedSearch] = useDebouncedValue(search, 500);
  const minSearchLength = 3;
  const { data: searchResults, isFetching } = useGetDashboardSearchResultQuery(
    { organisationId: organisation!.id, searchQuery: debouncedSearch },
    { skip: !organisation || debouncedSearch.length < minSearchLength },
  );
  const { data: stocks, isLoading } = useGetIndividualStocksQuery({
    organisationId: organisation!.id,
    filterQuery: filter.toQueryString(),
  });

  const doDownloadStockBalance = async () => {
    try {
      setIsFetchingExport(true);
      await downloadIndividualStockBreakdown(organisation?.id!, filter.toQueryString(), accessToken!);
    } finally {
      setIsFetchingExport(false);
    }
  };

  const userStorageItem = JSON.parse(userData?.storageBody ?? "{}") as UserStorageItem;
  const { isMobileSize, initialized } = useMobileDetect();
  const isMobile = initialized ? isMobileSize : false;

  useEffect(() => {
    if (userReadCount > 0) {
      setUserReadCount(userReadCount + 1);
      return;
    }

    const localUserStorageItem = JSON.parse(userData?.storageBody ?? "{}") as UserStorageItem;
    const dashFilter = localUserStorageItem?.ptDashFilter ?? {};

    setFilter(filter2 => {
      let clearedFilter = filter2.clearItems();

      for (const key in clearedFilter.values) {
        if (dashFilter.hasOwnProperty(key)) {
          clearedFilter = clearedFilter.overwrite(key as DashFilterKey, dashFilter[key as DashFilterKey]);
        }
      }
      return clearedFilter;
    });
    setSearch("");
    setUserReadCount(userReadCount + 1);
  }, [organisation?.id, userData?.storageBody]);

  const updateFilter = (filter: DashFilter) => {
    setFilter(filter);
    UpdateUserStorage({ body: { ...userStorageItem, ptDashFilter: filter.values } });
  };

  return (
    <Page titleKey={"navigation.individualStocks"} hasAccess={hasAccess} isMobile={isMobile} responsive>
      <div
        className={
          "grid grid-cols-4 col-span-4 v-tablet:grid-cols-3 items-center pt-4 pb-8 gap-4 justify-end" +
          (isMobile ? " pb-6 px-6 mt-[18px]" : "")
        }
      >
        <div className={"col-span-3"}>
          <SearchBar
            filter={filter}
            setFilter={updateFilter}
            searchString={search}
            setSearch={setSearch}
            searchResult={searchResults ?? defaultFilterData}
            loading={isFetching || loadingUser}
            minSearchLength={minSearchLength}
            id="individual-stock-searchbar"
          />
        </div>
        <div className={"col-span-1 v-tablet:col-span-3"}>
          <SeasonSelectElement filter={filter} setFilter={updateFilter} organisation={organisation!} />
        </div>
      </div>

      <IndividualStockOverview
        organisationId={organisation!.id}
        isLoading={isLoading}
        filter={filter}
        individualStocks={stocks ?? []}
        exportIsLoading={isFetchingExport}
        onRequestExport={doDownloadStockBalance}
      />

      <IndividualStocksReport
        organisationId={organisation!.id}
        isLoading={isLoading}
        filter={filter}
        individualStocks={stocks ?? []}
        exportIsLoading={isFetchingExport}
        onRequestExport={doDownloadStockBalance}
      ></IndividualStocksReport>
    </Page>
  );
}
