import { portalApi, publicPortalApi } from "./base/PortalApi";
import type { MaybeDrafted } from "@reduxjs/toolkit/src/query/core/buildThunks";
import type { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { ResultBase } from "./models/Models";
import { Organisation, OrganisationBindingRole, OrganisationId, UserProfile, UserWithRole } from "./models/UserProfile";
import { IFilterItem } from "@impulso/common/filters/generalFilter";
import { DashFilterKey } from "src/pages/new/Dashboard";

export const userApi = portalApi.injectEndpoints({
  endpoints: builder => ({
    getUser: builder.query<UserResponse, void>({
      query: () => "/api/user/me",
      providesTags: ["user"],
      transformResponse: ResultBase.unwrap,
    }),
    updateUserStorage: builder.mutation<void, { body: UserStorageItem }>({
      query: params => ({
        url: "/api/user/UpdateUserStorage",
        method: "POST",
        body: { item: JSON.stringify(params.body) },
      }),
      invalidatesTags: ["user"],
    }),
    setUserEmailAgreement: builder.mutation<void, boolean>({
      query: agreement => ({
        url: "/api/user/UpdateUserAgreement",
        method: "POST",
        body: { agreement },
      }),
      onQueryStarted: applyPatchToUser((newAgreement, draft) => {
        draft.user.emailAgreement = newAgreement;
      }),
      transformResponse: ResultBase.unwrap,
    }),
    getUsers: builder.query<UserWithRole[], { organisationId: OrganisationId }>({
      query: params => `/api/user/GetUsers?organisationId=${params.organisationId}`,
      providesTags: ["users"],
    }),
    getOrganisationTree: builder.query<OrganisationInfo[], { organisationId: OrganisationId }>({
      query: params => `/api/user/GetOrganisationTree?organisationId=${params.organisationId}`,
    }),
    updateUser: builder.mutation<
      void,
      { organisationId: OrganisationId; userId: string; newRole: OrganisationBindingRole }
    >({
      query: params => ({
        url: `/api/user/UpdateUserRole?organisationId=${params.organisationId}&userId=${params.userId}`,
        method: "POST",
        body: { newRole: params.newRole },
      }),
      invalidatesTags: ["users"],
    }),
    removeUser: builder.mutation<void, { organisationId: OrganisationId; userId: string }>({
      query: params => ({
        url: `/api/user/RemoveUserFromOrganisation?organisationId=${params.organisationId}&userId=${params.userId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["users"],
    }),
    inviteUser: builder.mutation<
      void,
      {
        organisationId: OrganisationId;
        invitedOrganisationId: OrganisationId;
        email: string;
        role: OrganisationBindingRole;
      }
    >({
      query: params => ({
        url: `/api/user/InviteUser?organisationId=${params.organisationId}`,
        method: "POST",
        body: { invitedOrganisationId: params.invitedOrganisationId, email: params.email, role: params.role },
      }),
      invalidatesTags: ["users"],
    }),
    updateUserProfile: builder.mutation<void, { userId: string; name: string; phoneNumber: string }>({
      query: params => ({
        url: `/api/user/UpdateUserProfile`,
        method: "POST",
        body: { ...params },
      }),
      invalidatesTags: ["user"],
    }),
    acceptInvitation: builder.mutation<NewOrganisation, { invitationId: string }>({
      query: params => ({
        url: `/api/user/AcceptInvitation`,
        method: "POST",
        body: { invitationId: params.invitationId },
      }),
      invalidatesTags: ["user"],
    }),
  }),
});

export const publicUserApi = publicPortalApi.injectEndpoints({
  endpoints: builder => ({
    createAccount: builder.mutation<
      NewOrganisation,
      { invitationId: string; name: string; phoneNumber?: string; password: string }
    >({
      query: params => ({
        url: `/api/user/CreateAccount`,
        method: "POST",
        body: {
          invitationId: params.invitationId,
          name: params.name,
          phoneNumber: params.phoneNumber,
          password: params.password,
        },
      }),
    }),
    getAlreadyCreatedUser: builder.query<AlreadyCreatedUserResponse, { query: { invitationId: string } }>({
      query: ({ query }) => `/api/user/GetAlreadyCreatedUser?invitationId=${query.invitationId}`,
    }),
  }),
});

export interface UserResponse {
  user: UserProfile;
  storageBody?: string;
  organisations?: Organisation[];
}

export interface AlreadyCreatedUserResponse {
  alreadyCreated: boolean;
  organisationName: string;
}

export interface UserStorageItem {
  dashboardFilter: Record<DashFilterKey, IFilterItem[]>;
  dashboardToggle?: string;
  ptDashFilter: Record<DashFilterKey, IFilterItem[]>;
  pinnedPanels?: Array<string>;
  articleUploadHeaders?: SupplierSavedHeaders[];
  articleDownloadHeaders?: SavedDownloadHeaders[];
}

export interface SupplierSavedHeaders {
  supplierId: OrganisationId;
  savedHeaders: SavedHeader[];
}

export interface SavedHeader {
  impulsoHeader: string;
  savedHeader: string;
}

export interface SavedDownloadHeaders {
  organisationId: OrganisationId;
  savedHeaders: DownloadHeader[];
}

export interface DownloadHeader {
  impulsoHeader: string;
  savedHeader: string;
  hidden: boolean;
}

export interface OrganisationInfo {
  id: OrganisationId;
  name: string;
}

export interface NewOrganisation {
  organisationName: string;
}

export type NotificationCategory = "payment" | "stock" | "delivery" | "agreement";

export interface Notification {
  id: string;
  category: NotificationCategory;
  title: string;
  date: string;
  read: boolean;
  archived: boolean;
  action?: { type: "internal" | "external"; path: string };
}

export const {
  useGetUserQuery,
  useUpdateUserStorageMutation,
  useSetUserEmailAgreementMutation,
  useGetUsersQuery,
  useGetOrganisationTreeQuery,
  useUpdateUserMutation,
  useRemoveUserMutation,
  useInviteUserMutation,
  useUpdateUserProfileMutation,
  useAcceptInvitationMutation,
} = userApi;

export const { useCreateAccountMutation, useGetAlreadyCreatedUserQuery } = publicUserApi;

/** Allows a mutation to modify the user response directly, without having to re-fetch the user object */
function applyPatchToUser<Q, P extends Promise<any>>(
  patchFunction: (query: Q, draft: MaybeDrafted<UserResponse>) => void,
) {
  return (query: Q, api: { dispatch: ThunkDispatch<any, any, AnyAction>; queryFulfilled: P }) => {
    const patchResult = api.dispatch(
      userApi.util.updateQueryData("getUser", undefined, draft => {
        if (draft) {
          patchFunction(query, draft);
        }
        return draft;
      }),
    );
    api.queryFulfilled.catch(() => patchResult.undo());
  };
}
