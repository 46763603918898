import { Page } from "@impulso/common/components/Page";
import { CalenderButton } from "@impulso/common/components/calender/CalenderButton";
import SearchBar from "@impulso/common/components/searchBar/SearchBar";
import { IFilterItem, generalFilter } from "@impulso/common/filters/generalFilter";
import useMobileDetect from "@impulso/common/hooks/useMobileDetect";
import { useDebouncedValue } from "@mantine/hooks";
import { format } from "date-fns/format";
import { subDays } from "date-fns/subDays";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserStorageItem, useGetUserQuery, useUpdateUserStorageMutation } from "src/api/UserApi";
import { SeasonSelectElement } from "src/common/components/dropdown/SeasonSelect";
import DeliveriesReport from "src/modules/stock/DeliveriesReport";
import { useGetDashboardSearchResultQuery, useGetDeliveriesQuery } from "../../api/ReportApi";
import { useHasModule, useOrganisation } from "../../common/security/UseGlobalSecurity";
import { DashFilter, DashFilterKey } from "../new/Dashboard";

const defaultFilterData: Record<DashFilterKey, IFilterItem[]> = {
  styleNames: [],
  styleNumbers: [],
  seasons: [],
  brands: [],
  departments: [],
  retailers: [],
  suppliers: [],
  stores: [],
  categories: [],
  eans: [],
  colors: [],
  sizes: [],
};

export default function Deliveries() {
  const organisation = useOrganisation();
  const hasAccess = useHasModule("impulso.product-tracker.view");
  const { isMobileSize, initialized } = useMobileDetect();
  const { data: userData, isLoading: loadingUser } = useGetUserQuery(undefined);
  const [userReadCount, setUserReadCount] = useState(0);
  const [UpdateUserStorage] = useUpdateUserStorageMutation();
  const [filter, setFilter] = useState(generalFilter(defaultFilterData));
  const [search, setSearch] = useState("");
  const [dateSpan, setDateSpan] = useState<[Date, Date]>([subDays(new Date(), 30), new Date()]);
  const navigate = useNavigate();
  const { data: deliveries, isLoading } = useGetDeliveriesQuery({
    organisationId: organisation!.id,
    startDate: format(dateSpan[0], "yyyy-MM-dd"),
    endDate: format(dateSpan[1], "yyyy-MM-dd"),
    filterQuery: filter.toQueryString(),
  });
  const userStorageItem = JSON.parse(userData?.storageBody ?? "{}") as UserStorageItem;

  const isMobile = initialized ? isMobileSize : false;

  useEffect(() => {
    if (userReadCount > 0) {
      setUserReadCount(userReadCount + 1);
      return;
    }

    const userStorageItem = JSON.parse(userData?.storageBody ?? "{}") as UserStorageItem;
    const dashFilter = userStorageItem?.ptDashFilter ?? {};

    setFilter(filter2 => {
      let clearedFilter = filter2.clearItems();

      for (const key in clearedFilter.values) {
        if (dashFilter.hasOwnProperty(key)) {
          clearedFilter = clearedFilter.overwrite(key as DashFilterKey, dashFilter[key as DashFilterKey]);
        }
      }
      return clearedFilter;
    });
    setSearch("");
    setUserReadCount(userReadCount + 1);
  }, [organisation?.id, userData?.storageBody]);

  const [debouncedSearch] = useDebouncedValue(search, 500);
  const minSearchLength = 3;
  const { data: searchResults, isFetching } = useGetDashboardSearchResultQuery(
    { organisationId: organisation!.id, searchQuery: debouncedSearch },
    { skip: !organisation || debouncedSearch.length < minSearchLength },
  );

  const updateFilter = (filter: DashFilter) => {
    setFilter(filter);
    UpdateUserStorage({ body: { ...userStorageItem, ptDashFilter: filter.values } });
  };

  return (
    <Page titleKey={"navigation.deliveries"} hasAccess={hasAccess} isMobile={isMobile} responsive>
      <div
        className={
          "grid grid-cols-5 col-span-12 h-tablet:grid-cols-3 h-tablet:col-span-6 items-center pt-4 pb-8 gap-4 justify-end" +
          (isMobile ? " pb-6 px-6 mt-[18px]" : "")
        }
      >
        <div className={"col-span-3"}>
          <SearchBar
            filter={filter}
            setFilter={updateFilter}
            searchString={search}
            setSearch={setSearch}
            searchResult={searchResults ?? defaultFilterData}
            loading={isFetching || loadingUser}
            minSearchLength={minSearchLength}
            id="deliveries-searchbar"
          />
        </div>
        <div className={"col-span-1 mobile:col-span-3"}>
          <SeasonSelectElement filter={filter} setFilter={updateFilter} organisation={organisation!} />
        </div>
        <div className={"col-span-1 h-tablet:col-span-2 mobile:col-span-3"}>
          <CalenderButton
            setDateSpan={setDateSpan}
            dateSpan={dateSpan}
            boxSize=" h-tablet:w-full h-[48px]"
            defaultTitle="Last 30 Days"
          />
        </div>
      </div>
      <DeliveriesReport
        organisationId={organisation!.id}
        isLoading={isLoading}
        deliveries={deliveries ?? []}
        filter={filter}
      ></DeliveriesReport>
    </Page>
  );
}
