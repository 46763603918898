import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useGetUserQuery } from "../../api/UserApi";
import { GlobalAppsettings } from "../GlobalSettings";
import { Organisation, OrganisationType } from "../../api/models/UserProfile";
import { ModuleId } from "../../api/models/Module";

export const useGlobalSecurity = () => {
  const { loginWithRedirect, logout } = useAuth0();
  const accessToken = useSelector((s: RootState) => s.user.token);

  const organisation = useOrganisation();

  return {
    accessToken,
    login: loginWithRedirect,
    logout: () => logout({ returnTo: GlobalAppsettings.LogoutUri }),
    hasModule: (id: ModuleId) => hasModule(organisation, id),
  };
};

export function useOrganisation(): Organisation | undefined {
  const user = useCurrentUser();
  const organisationId = useOrganisationId();
  const org = organisationId && user?.organisations?.find(o => o.id === organisationId);
  return org;
}

export function useCurrentUser() {
  const accessToken = useSelector((s: RootState) => s.user.token);

  const { data: user } = useGetUserQuery(undefined, { skip: !accessToken });

  return user;
}

export function useOrganisationId() {
  return useSelector((s: RootState) => s.organisation.currentOrganisationId);
}

export function useHasModule(moduleId: ModuleId): boolean {
  const organisation = useOrganisation();
  return hasModule(organisation, moduleId);
}

export function hasModule(orgOrModules: Organisation | ModuleId[] | undefined, moduleId: ModuleId): boolean {
  const organisationModules = Array.isArray(orgOrModules) ? orgOrModules : orgOrModules?.modules.map(m => m.identifier);

  if (organisationModules !== undefined) {
    const module = organisationModules?.find(x => moduleId.toLowerCase().startsWith(x.toLowerCase()));
    if (module !== undefined) {
      return true;
    }
  }
  return false;
}

/**
 * Calculate the type of an organisation based on which modules it has.
 *
 * If no modules are specified, "impulso.*.dashboard" will be used
 *
 * Specialised version of {@link identifierFromModule}
 */
export function organisationTypeFromModule(
  organisation: Organisation | undefined,
  moduleBindings?: [identifier: OrganisationType, module: ModuleId][],
): OrganisationType | undefined {
  const DEFAULT_MODULES: [identifier: OrganisationType, module: ModuleId][] = [
    ["supplier", "impulso.supplier.dashboard"],
    ["retailer", "impulso.store.dashboard"],
  ];

  moduleBindings ??= DEFAULT_MODULES;

  return identifierFromModule(organisation, moduleBindings);
}

/** Returns first identifier for which the organisation has a module */
export function identifierFromModule<T extends string>(
  organisation: Organisation | undefined,
  moduleBindings: [identifier: T, module: ModuleId][],
): T | undefined {
  for (const [identifier, moduleId] of moduleBindings) {
    if (hasModule(organisation, moduleId)) {
      return identifier;
    }
  }

  return undefined;
}

/** React hook version of {@link identifierFromModule} */
export function useIdentifierFromModule<T extends string>(
  moduleBindings: [identifier: T, module: ModuleId][],
): T | undefined {
  const organisation = useOrganisation();
  return identifierFromModule(organisation, moduleBindings);
}
