import { portalApi } from "./base/PortalApi";
import { OrganisationId } from "./models/UserProfile";

export const notificationApi = portalApi.injectEndpoints({
  endpoints: builder => ({
    getNotifications: builder.query<OrganisationNotificationResponse[], { organisationId: OrganisationId }>({
      query: params => `/api/notifications?organisationId=${params.organisationId}`,
      providesTags: ["notifications"],
    }),
    readNotification: builder.mutation<
      OrganisationNotificationResponse,
      { organisationId: OrganisationId; notificationId: string }
    >({
      query: params => ({
        url: `/api/notifications/${params.notificationId}/read?organisationId=${params.organisationId}`,
        method: "POST",
      }),
      onQueryStarted: async (request, { dispatch, queryFulfilled }) => {
        const result = await queryFulfilled;
        dispatch(
          notificationApi.util.updateQueryData("getNotifications", { organisationId: request.organisationId }, draft =>
            draft.map(n => (n.id === request.notificationId ? result.data : n)),
          ),
        );
      },
    }),
  }),
});

export interface PaymentCompletedMeta {
  RetailerId: OrganisationId;
  RetailerName: string;
  AmountMinor: number;
  Currency: string;
  Date: string;
}

export interface InvoiceAgreementCreatedMeta {
  AgreementId: string;
  InvoiceNo: string;
  RetailerName: string;
  DueDate: string;
}

export interface DeclinedWithdrawRetailerMeta {
  SupplierId: OrganisationId;
  SupplierName: string;
  AmountMinor: number;
  Currency: string;
  Date: string;
}

export interface DeclinedWithdrawSupplierMeta {
  RetailerId: OrganisationId;
  RetailerName: string;
  AmountMinor: number;
  Currency: string;
  Date: string;
}

export interface RetryWithdrawalSupplierMeta {
  RetailerId: OrganisationId;
  RetailerName: string;
  AmountMinor: number;
  Currency: string;
  Date: string;
}
export interface RetryWithdrawalRetailerMeta {
  AmountMinor: number;
  RetailerFee: number;
  Currency: string;
  Date: string;
}

export interface AddedNewUserMeta {
  Name: string;
  Role: string;
  OrgName: string;
  Date: string;
}

export interface RemovedUserMeta {
  Name: string;
  Role: string;
  OrgName: string;
  Date: string;
}

export interface UploadedArticlesMeta {
  SupplierId: OrganisationId;
  SupplierName: string;
  Brand: string;
  SeasonId: string;
  ArticleCount: string;
  Date: string;
}

export type NotificationMeta =
  | PaymentCompletedMeta
  | InvoiceAgreementCreatedMeta
  | DeclinedWithdrawRetailerMeta
  | DeclinedWithdrawSupplierMeta
  | RetryWithdrawalRetailerMeta
  | RetryWithdrawalSupplierMeta
  | AddedNewUserMeta
  | RemovedUserMeta
  | UploadedArticlesMeta;

export type NotificationStatus = "Unread" | "Pending" | "CompletedByUser" | "CompletedBySystem";
export type NotificationType =
  | "PaymentCompleted"
  | "InvoiceAgreementCreated"
  | "DeclinedWithdrawRetailer"
  | "DeclinedWithdrawSupplier"
  | "RetryWithdrawalSupplier"
  | "RetryWithdrawalRetailer"
  | "AddedNewUser"
  | "RemovedUser"
  | "UploadedArticles";
export interface OrganisationNotificationResponse {
  id: string;
  status: NotificationStatus;
  type: NotificationType;
  created: string;
  meta: string;
}

export const { useGetNotificationsQuery, useReadNotificationMutation } = notificationApi;
