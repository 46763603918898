import { OrganisationRequest, portalApi } from "./base/PortalApi";
import { OrganisationId } from "./models/UserProfile";

export const integrationsApi = portalApi.injectEndpoints({
  endpoints: builder => ({
    getStoreBindings: builder.query<OrganisationBindingResult, OrganisationRequest<{ type: IntegrationType }>>({
      query: args => `/api/${args.type}/GetStoreBindings?organisationId=${args.organisationId}`,
      providesTags: ["store-bindings"],
    }),
    updateStoreBindings: builder.mutation<
      void,
      OrganisationRequest<{ type: IntegrationType; body: UpdateOrganisationBindingRequest }>
    >({
      query: args => ({
        method: "POST",
        url: `/api/${args.type}/UpdateStoreBindings?organisationId=${args.organisationId}`,
        body: args.body,
      }),
      invalidatesTags: ["store-bindings"],
    }),
    getInventoryBindings: builder.query<OrganisationBindingResult, OrganisationRequest<{ type: IntegrationType }>>({
      query: args => `/api/${args.type}/GetInventoryBindings?organisationId=${args.organisationId}`,
      providesTags: ["store-bindings"],
    }),
    updateInventoryBindings: builder.mutation<
      void,
      OrganisationRequest<{ type: IntegrationType; body: UpdateOrganisationBindingRequest }>
    >({
      query: args => ({
        method: "POST",
        url: `/api/${args.type}/UpdateInventoryBindings?organisationId=${args.organisationId}`,
        body: args.body,
      }),
      invalidatesTags: ["store-bindings"],
    }),

    /** Shopify **************************************************************************************** Shopify **/
    getShopifyAuthUri: builder.query<string, { organisationId: OrganisationId; state: string; shop: string }>({
      query: args =>
        `/api/shopify/GetAuthUri?organisationId=${args.organisationId}&state=${args.state}&shop=${encodeURIComponent(args.shop)}`,
    }),
    createShopifyConnection: builder.mutation<{}, { organisationId: OrganisationId; code: string; shop: string }>({
      query: args => ({
        url: `/api/shopify/CreateConnection?organisationId=${args.organisationId}`,
        body: {
          code: args.code,
          shop: encodeURIComponent(args.shop),
        },
        method: "POST",
      }),
      invalidatesTags: ["integration"],
    }),
    getShopifyConnectionStatus: builder.query<
      { status: "NotConnected" | "Error" | "Connected" },
      { organisationId: OrganisationId }
    >({
      query: args => `/api/shopify/GetConnectionStatus?organisationId=${args.organisationId}`,
      providesTags: ["integration"],
    }),
    /** Fortnox **************************************************************************************** Fortnox **/
    getFortnoxConnectionStatus: builder.query<
      { status: "NotConnected" | "Error" | "Connected" },
      { organisationId: OrganisationId }
    >({
      query: args => `/api/fortnox/GetConnectionStatus?organisationId=${args.organisationId}`,
      providesTags: ["integration"],
    }),
    getAuthUri: builder.query<string, { organisationId: OrganisationId; state: string }>({
      query: args => `/api/fortnox/GetAuthUri?organisationId=${args.organisationId}&state=${args.state}`,
    }),
    createFortnoxConnection: builder.mutation<{}, { organisationId: OrganisationId; code: string }>({
      query: args => ({
        url: `/api/fortnox/CreateConnection?organisationId=${args.organisationId}`,
        body: {
          code: args.code,
        },
        method: "POST",
      }),
      invalidatesTags: ["integration"],
    }),
    /** Sitoo **************************************************************************************** Fortnox **/
    getSitooConnectionStatus: builder.query<
      { status: "NotConnected" | "Error" | "Connected" },
      { organisationId: OrganisationId }
    >({
      query: args => `/api/sitoo/GetConnectionStatus?organisationId=${args.organisationId}`,
      providesTags: ["integration"],
    }),
    createSitooConnection: builder.mutation<{}, { organisationId: OrganisationId; code: string }>({
      query: args => ({
        url: `/api/sitoo/CreateConnection?organisationId=${args.organisationId}`,
        body: {
          code: args.code,
        },
        method: "POST",
      }),
      invalidatesTags: ["integration"],
    }),
  }),
});

export type IntegrationType = "fortnox" | "front-systems" | "shopify" | "sitoo";

export interface OrganisationBindingResult {
  bindings: OrganisationBinding[];
  organisations: {
    organisationId: OrganisationId;
    name: string;
  }[];
}

export interface UpdateOrganisationBindingRequest {
  bindings: OrganisationBindingRequest[];
}

export interface OrganisationBinding {
  bindingId: string;
  name: string;
  organisationId: OrganisationId | null;
}

export interface OrganisationBindingRequest {
  bindingId: string;
  organisationId: OrganisationId | null;
}

export const {
  useGetFortnoxConnectionStatusQuery,
  useGetStoreBindingsQuery,
  useUpdateStoreBindingsMutation,
  useGetInventoryBindingsQuery,
  useUpdateInventoryBindingsMutation,
  useLazyGetAuthUriQuery,
  useLazyGetShopifyAuthUriQuery,
  useGetShopifyConnectionStatusQuery,
  useGetSitooConnectionStatusQuery,
  useCreateSitooConnectionMutation,
} = integrationsApi;
