import { Page } from "@impulso/common/components/Page";
import { PrimaryButton } from "@impulso/common/components/buttons/PrimaryButton";
import { SecondaryButton } from "@impulso/common/components/buttons/SecondaryButton";
import useMobileDetect from "@impulso/common/hooks/useMobileDetect";
import { Checkbox, Loader, Title } from "@mantine/core";
import { Form, useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";
import {
  UserStorageItem,
  useGetUserQuery,
  useSetUserEmailAgreementMutation,
  useUpdateUserProfileMutation,
  useUpdateUserStorageMutation,
} from "src/api/UserApi";
import { UserProfile } from "src/api/models/UserProfile";
import { TextInputField } from "@impulso/common/components/inputs/inputField";

export default function UserPreferences() {
  const { data: user, error: userError, isFetching: userLoading } = useGetUserQuery();
  const emailAgreement = user?.user?.emailAgreement;
  const [updateEmailAgreement, { isLoading: isUpdatingAgreement }] = useSetUserEmailAgreementMutation();
  const { isMobileSize, initialized } = useMobileDetect();

  if (userLoading) {
    return (
      <Page titleKey="userPrefs.title" hasAccess>
        <div className="w-full, h-full min-h-[404px] flex items-center justify-center">
          <Loader />
        </div>
      </Page>
    );
  }

  return (
    <Page titleKey="userPrefs.title" hasAccess responsive grid isMobile={isMobileSize}>
      <UserInfoForm user={user!.user} />
      <div
        className={
          "desktop-plus:col-span-12 col-span-6 grid desktop-plus:grid-cols-2 grid-cols-1 h-fit " +
          (isMobileSize ? "gap-0" : "gap-6")
        }
      >
        <EmailAgreementPreferences
          emailAgreement={emailAgreement}
          updateEmailAgreement={updateEmailAgreement}
          isUpdatingAgreement={isUpdatingAgreement}
        />
        <UserStoragePreferences />
      </div>
    </Page>
  );
}

function UserInfoForm(props: { user: UserProfile }) {
  const [updateUserProfile] = useUpdateUserProfileMutation();
  const form = useForm({
    initialValues: {
      name: props.user.name,
      phoneNumber: props.user.phoneNumber,
    },
    validate: {
      name: value => {
        return value.length === 0;
      },
    },
  });

  return (
    <div className="border border-gray-300 p-4 pt-2 col-span-6">
      <Title size="h2" className="my-2" weight={400}>
        Edit Profile
      </Title>
      <Form
        form={form}
        onSubmit={values => {
          updateUserProfile({
            userId: props.user.id,
            name: values.name,
            phoneNumber: values.phoneNumber ?? "",
          });
        }}
      >
        <div className="max-w-[500px] pb-4">
          <TextInputField label="Name" required={false} {...form.getInputProps("name")} placeholder="Name Nameson" />
          <TextInputField
            label="Phone Number"
            required={false}
            {...form.getInputProps("phoneNumber")}
            placeholder="+XX 0011 223344"
          />
          <TextInputField
            label="E-mail Address"
            required={false}
            value={props.user.email}
            placeholder="example@email.com"
            disabled
          />
        </div>
        <PrimaryButton extraStyle="w-min" label="Save" disabled={!form.isDirty()} type="submit" />
      </Form>
    </div>
  );
}

interface EmailAgreementPreferencesProps {
  emailAgreement: boolean | undefined;
  updateEmailAgreement: (value: boolean) => void;
  isUpdatingAgreement: boolean;
}

function EmailAgreementPreferences(props: EmailAgreementPreferencesProps) {
  const { t } = useTranslation();

  return (
    <div className="col-span-1">
      <div className="border-gray-300 p-4 pt-2 border mobile:border-t-0">
        <Title size="h2" className="my-2" weight={400}>
          {t("userPrefs.notificationSubHeader")}
        </Title>
        <Checkbox
          disabled={props.isUpdatingAgreement}
          checked={props.emailAgreement}
          styles={{ input: { borderRadius: 0 } }}
          onChange={event => props.updateEmailAgreement(event.currentTarget.checked)}
          label={t("userPrefs.emailAgreement")}
          className="py-2"
        />
      </div>
    </div>
  );
}

function UserStoragePreferences() {
  const { t } = useTranslation();
  const [UpdateUserStorage] = useUpdateUserStorageMutation();

  const clearStorage: UserStorageItem = {
    dashboardFilter: {
      styleNumbers: [],
      brands: [],
      departments: [],
      categories: [],
      colors: [],
      eans: [],
      retailers: [],
      seasons: [],
      sizes: [],
      stores: [],
      suppliers: [],
      styleNames: [],
    },
    ptDashFilter: {
      styleNumbers: [],
      brands: [],
      departments: [],
      categories: [],
      colors: [],
      eans: [],
      retailers: [],
      seasons: [],
      sizes: [],
      stores: [],
      suppliers: [],
      styleNames: [],
    },
  };

  return (
    <div className="col-span-1">
      <div className="border-gray-300 p-4 pt-2 border mobile:border-t-0">
        <Title size="h2" className="my-2" weight={400}>
          {t("userPrefs.storageSubHeader")}
        </Title>
        <p className="text-M">
          Impulso stores your last search and filters between sessions, if you want to clear this information; click the
          button below.
        </p>
        <SecondaryButton
          extraStyle="mt-4"
          label="Clear User Storage"
          onClick={() => {
            if (window.confirm("Are you sure?")) {
              UpdateUserStorage({ body: clearStorage });
            }
          }}
        />
      </div>
    </div>
  );
}
