import { SimpleBadge } from "@impulso/common/components/Badge";
import { PrimaryButton } from "@impulso/common/components/buttons/PrimaryButton";
import { SecondaryButton } from "@impulso/common/components/buttons/SecondaryButton";
import { LinkButton } from "@impulso/common/components/link";
import { AccessDenied, Page } from "@impulso/common/components/Page";
import { TableReportColumn } from "@impulso/common/components/report/DataTable";
import { TableReport } from "@impulso/common/components/report/TableReport";
import { IFilter, IFilterItem, generalFilter } from "@impulso/common/filters/generalFilter";
import useMobileDetect from "@impulso/common/hooks/useMobileDetect";
import ArrowLeft from "@impulso/common/Icons/ArrowLeft";
import Download from "@impulso/common/Icons/Download";
import Plus from "@impulso/common/Icons/Plus";
import { Language } from "@impulso/common/Language";
import { dropdownStyling } from "@impulso/common/styling/DropdownStyling";
import FormatDate from "@impulso/common/styling/FormatDate";
import { Checkbox, MultiSelect, Progress } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { format } from "date-fns/format";
import { parse } from "date-fns/parse";
import Fuse from "fuse.js";
import { useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { downloadInvoicePayouts } from "src/api/DownloadApi";
import { OrganisationId } from "src/api/models/UserProfile";
import SimpleSearch from "src/common/components/filters/SimpleSearch";
import { useGetAgreementsQuery } from "../../api/AgreementApi";
import FilterHeader from "../../common/components/filters/FilterHeader";
import { useGlobalSecurity, useHasModule, useOrganisationId } from "../../common/security/UseGlobalSecurity";
import Paths from "../../configuration/Paths";
import {
  AgreementResult,
  AgreementType,
  InvoiceAgreementResult,
  ProductTrackerAgreementResult,
  Result,
  ResultItem,
  SplitAgreementResult,
  WholesaleAgreementResult,
} from "../../modules/agreements/api/Agreement";

type AgreementFilterKey = "agreementType" | "status" | "brands";
type AgreementFilter = IFilter<AgreementFilterKey>;

const defaultFilter: Record<AgreementFilterKey, IFilterItem[]> = {
  agreementType: [],
  status: [],
  brands: [],
};

function TypeToLabel(type: AgreementType) {
  switch (type) {
    case "Invoice":
    case "Split":
    case "Wholesale":
      return type;
    case "ProductTracker":
      return "Product Tracker";
  }
}

function GetColumnData(isRetailer: boolean): TableReportColumn<AgreementResult>[] {
  const oppositeOrg: TableReportColumn<AgreementResult> = isRetailer
    ? { accessor: "supplier", sortable: true, title: "Supplier", visibility: "alwaysVisible" }
    : { accessor: "retailer", sortable: true, title: "Retailer", visibility: "alwaysVisible" };

  return [
    oppositeOrg,
    {
      accessor: "brands",
      sortable: false,
      title: "Brands",
      visibility: "visible",
      render: record => (
        <div className="relative w-full min-w-[120px]">
          <div className="flex items-center v-tablet:flex-col v-tablet:items-start gap-2 overflow-x-scroll no-scrollbar">
            {record.brands.map(brand => (
              <SimpleBadge className="h-6">{brand.toUpperCase()}</SimpleBadge>
            ))}
          </div>
        </div>
      ),
    },
    {
      accessor: "type",
      sortable: true,
      title: "Type",
      visibility: "alwaysVisible",
      render: record => <p className="w-full truncate">{TypeToLabel(record.type)}</p>,
    },
    {
      accessor: "startDate",
      sortable: true,
      title: "Start Date",
      visibility: "alwaysVisible",
      render: record => FormatDate(record.startDate),
    },
    {
      accessor: "isActive",
      sortable: true,
      title: "Status",
      visibility: "visible",
      definedWidth: "min-w-[100px]",
      render: record => (
        <div className="min-w-[100px] truncate flex items-center gap-2 text-left">
          {record.isActive ? (
            <div className="h-2 w-2 rounded bg-confirmation"></div>
          ) : (
            <div className="h-2 w-2 rounded bg-gray-400"></div>
          )}
          <p>{record.isActive ? "Active" : "Inactive"}</p>
        </div>
      ),
    },
  ];
}

export interface AgreementLocationState {
  search?: string;
}

export default function Agreements() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<AgreementFilter>(generalFilter(defaultFilter));
  const location = useLocation();
  const locState = location.state as AgreementLocationState | null;
  const [searchString, setSearchString] = useState(locState?.search ?? "");
  const [debouncedSearch] = useDebouncedValue(searchString, 500);

  const hasAccess = useHasModule("impulso.agreements.view");
  const isRetailer = useHasModule("impulso.store.dashboard");
  const canCreateAgreements = useHasModule("impulso.agreements.create");
  const { isMobileSize, initialized } = useMobileDetect();
  const { accessToken } = useGlobalSecurity();
  const { id } = useParams();
  const organisationId = useOrganisationId();
  const { data: dataAgreements, isLoading } = useGetAgreementsQuery(
    { organisationId: organisationId! },
    { skip: !organisationId },
  );

  const agreements = dataAgreements
    ?.filter(a => a.agreement.supplierId == id || a.agreement.retailerId == id)
    .map(a => ({
      ...a,
      agreement: {
        ...a.agreement,
      },
      ...(a.agreement.type === "Invoice"
        ? {
            invoiceDate: format(
              parse((a as InvoiceAgreementResult).invoiceDate, "yyyy-MM-dd", new Date()),
              "dd MMM yyyy",
            ),
            dueDate: format(parse((a as InvoiceAgreementResult).dueDate, "yyyy-MM-dd", new Date()), "dd MMM yyyy"),
          }
        : {}),
    }));
  const uniqueBrands = [...new Set((agreements ?? []).flatMap(a => a.agreement.brands))].sort();

  const idx = useMemo(() => {
    if (isLoading) {
      return undefined;
    }

    const options = {
      threshold: 0.2,
      keys: ["agreement.retailer", "agreement.supplier"],
      shouldSort: true,
    };

    return new Fuse<ResultItem>(agreements ?? [], options);
  }, [agreements, isLoading]);

  const searchResult = useMemo(() => idx?.search(debouncedSearch), [idx, debouncedSearch]);
  function getResult(): Result {
    const searchString = debouncedSearch.trim();
    const agreementWithId = agreements?.filter(a => a.agreement.id === searchString);
    if (agreementWithId?.length === 1) {
      return agreementWithId;
    }

    const matchingInvoices = agreements?.filter(a => "invoiceNo" in a && a.invoiceNo === searchString) ?? [];

    if (matchingInvoices.length > 0) {
      return matchingInvoices;
    }

    if (searchString.length > 0 && searchResult) {
      // Fuzzy search
      return searchResult.map(i => i.item);
    } else if (agreements) {
      // Return whole list
      return agreements.slice().sort(function (a, b) {
        return Date.parse(b.agreement.startDate) - Date.parse(a.agreement.startDate);
      });
    }

    return [];
  }

  function filterAgreement(agreement: ResultItem): boolean {
    if (!agreement.agreement.brands.some(b => filter.validate("brands", b))) {
      return false;
    }

    if (!filter.validate("agreementType", agreement.agreement.type)) {
      return false;
    }

    if (!filter.validate("status", agreement.agreement.isActive ? "Active" : "Inactive")) {
      return false;
    }

    return true;
  }

  const filteredResult = getResult().filter(filterAgreement);

  const columnData = useMemo(() => GetColumnData(isRetailer), [isRetailer]);

  if (!hasAccess) {
    return <AccessDenied pageTitleKey="agreements.title" />;
  }

  function getIdAccessor(agreement: AgreementResult): string {
    return agreement.id;
  }

  function getResultItem(agreement: AgreementResult) {
    return filteredResult.find(result => result.agreement.id === agreement.id)!;
  }

  if (agreements == undefined) return <AccessDenied pageTitleKey={"no content"} />;

  return (
    <Page
      responsive
      titleKey={(isRetailer ? agreements[0]?.agreement.supplier : agreements[0]?.agreement.retailer) ?? " "}
      hasAccess={hasAccess}
      isMobile={isMobileSize && initialized}
      leftAction={
        <div className="flex v-tablet:flex-col flex-row mb-4 gap-4 mr-2">
          {canCreateAgreements && (
            <SecondaryButton
              leftIcon={<ArrowLeft />}
              onClick={() => navigate(Paths.agreements.partners, { state: { search: debouncedSearch } })}
              label="Back"
              extraStyle="v-tablet:w-full"
            ></SecondaryButton>
          )}
        </div>
      }
      rightAction={
        <div className="flex v-tablet:flex-col flex-row mb-4 gap-4 v-tablet:mt-[34px]">
          <SimpleSearch searchString={searchString} setSearchString={setSearchString} />
          {canCreateAgreements && (
            <PrimaryButton
              onClick={() => navigate(Paths.agreements.create)}
              label="Add new"
              rightIcon={<Plus />}
              extraStyle="w-min v-tablet:w-full"
            ></PrimaryButton>
          )}
        </div>
      }
    >
      <div className="mobile:pl-6">
        <FilterHeader filter={filter} updateFilter={setFilter}>
          <AgreementTypeDropdown filter={filter} setFilter={setFilter} />
          <BrandDropdown filter={filter} setFilter={setFilter} uniqueBrands={uniqueBrands} />
          <StatusDropdown filter={filter} setFilter={setFilter} />
        </FilterHeader>
      </div>
      <TableReport
        pageSize={25}
        rows={filteredResult.map(result => result.agreement)}
        columns={columnData}
        rowBody={agreement =>
          GetAgreementBody({
            resultItem: getResultItem(agreement),
            organisationId: organisationId!,
            accessToken: accessToken!,
            isRetailer,
          })
        }
        defaultSort={{
          accessor: "startDate",
          direction: "desc",
        }}
        idAccessor={getIdAccessor}
        isLoading={isLoading}
        loadingText={"Loading agreements..."}
      />
    </Page>
  );
}
function GetAgreementBody(props: {
  resultItem: ResultItem;
  organisationId: OrganisationId;
  accessToken: string;
  isRetailer: boolean;
}) {
  switch (props.resultItem.agreement.type) {
    case "Split":
      return <ConsignmentBody item={props.resultItem as SplitAgreementResult} isRetailer={props.isRetailer} />;
    case "Invoice":
      return (
        <InvoiceBody
          item={props.resultItem as InvoiceAgreementResult}
          organisationId={props.organisationId}
          accessToken={props.accessToken}
          isRetailer={props.isRetailer}
        />
      );
    case "Wholesale":
      return <WholesaleBody item={props.resultItem as WholesaleAgreementResult} isRetailer={props.isRetailer} />;
    case "ProductTracker":
      return (
        <ProductTrackerBody item={props.resultItem as ProductTrackerAgreementResult} isRetailer={props.isRetailer} />
      );
  }
}

function ConsignmentBody(props: { item: SplitAgreementResult; isRetailer: boolean }) {
  return (
    <div className="py-4 grid grid-cols-4 w-full gap-y-6 v-tablet:gap-y-4">
      <PairRowContainer>
        <BodyItem label="Company" value={props.item.agreement.retailer} />
        <BodyItem label="Started" value={props.item.agreement.startDate} />
      </PairRowContainer>
      <PairRowContainer>
        <BodyItem label="Retailer Fee" value={props.item.agreement.retailerFee.toFixed(2).toString() + "%"} />
        <BodyItem label="Supplier Fee" value={props.item.agreement.supplierFee.toFixed(2).toString() + "%"} />
      </PairRowContainer>

      <div className="col-span-4 grid grid-cols-2 v-tablet:gap-4">
        <PairContainer>
          <BodyItem label="Split" value={props.item.split.toString() + "/" + (100 - props.item.split).toString()} />
        </PairContainer>
        <div className="flex flex-col gap-2 pl-4 v-tablet:pl-0 v-tablet:col-span-2 v-tablet:pb-4">
          <div className="flex gap-2 items-end">
            <p className="text-sc text-gray-600 uppercase">Interval</p>
            <p className="font-semibold text-M">{props.item.agreement.paymentInterval}</p>
          </div>
          <div className="flex gap-2 items-end whitespace-nowrap">
            <p className="text-sc text-gray-600 uppercase">ID </p>
            <p className="semibold text-M text-gray-500 truncate">{props.item.agreement.id}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function WholesaleBody(props: { item: WholesaleAgreementResult; isRetailer: boolean }) {
  return (
    <div className="py-4 grid grid-cols-4 w-full gap-y-6 v-tablet:gap-y-4">
      <PairRowContainer>
        <BodyItem label="Company" value={props.item.agreement.retailer} />
        <BodyItem label="Started" value={props.item.agreement.startDate} />
      </PairRowContainer>
      <PairRowContainer>
        <BodyItem label="Retailer Fee" value={props.item.agreement.retailerFee.toFixed(2).toString() + "%"} />
        <BodyItem label="Supplier Fee" value={props.item.agreement.supplierFee.toFixed(2).toString() + "%"} />
      </PairRowContainer>

      <div className="col-span-4 grid grid-cols-2">
        <div className="v-tablet:hidden border-r"></div>
        <div className="flex flex-col gap-2 pl-4 v-tablet:pl-0 v-tablet:col-span-2 v-tablet:pb-4">
          <div className="flex gap-2 items-end">
            <p className="text-sc text-gray-600 uppercase">Interval</p>
            <p className="font-semibold text-M">{props.item.agreement.paymentInterval}</p>
          </div>
          <div className="flex gap-2 items-end whitespace-nowrap">
            <p className="text-sc text-gray-600 uppercase">ID </p>
            <p className="semibold text-M text-gray-500 truncate">{props.item.agreement.id}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function InvoiceBody(props: {
  item: InvoiceAgreementResult;
  organisationId: OrganisationId;
  accessToken: string;
  isRetailer: boolean;
}) {
  const invoiceTotal = props.item.amount / 100;
  const invoiceDisbursed = props.item.amountDisbursed / 100;
  const invoicePaid = props.item.amountPaid / 100;

  const doDownloadInvoice = () =>
    downloadInvoicePayouts(props.organisationId, props.item.agreement.id, props.item.invoiceNo, props.accessToken);

  return (
    <div className="py-4 grid grid-cols-4 w-full gap-y-6 v-tablet:gap-y-4">
      <PairContainer>
        <BodyItem label="Company" value={props.item.agreement.retailer} />
        <BodyItem label="Invoice No" value={props.item.invoiceNo} />
      </PairContainer>
      <PairContainer>
        <BodyItem label="Invoice Date" value={props.item.invoiceDate} />
        <BodyItem label="Due Date" value={props.item.dueDate} />
      </PairContainer>
      <PairContainer>
        <BodyItem label="Invoice Total" value={Language.FormatMoney(invoiceTotal, "SEK")} />
        <BodyItem
          label="Invoice Remaining"
          value={Language.FormatMoney(invoiceTotal - (props.isRetailer ? invoicePaid : invoiceDisbursed), "SEK")}
        />
      </PairContainer>
      <PairContainer>
        <BodyItem label="Retailer Fee" value={props.item.agreement.retailerFee.toFixed(2).toString() + "%"} />
        <BodyItem label="Supplier Fee" value={props.item.agreement.supplierFee.toFixed(2).toString() + "%"} />
      </PairContainer>

      <div className="col-span-4 grid grid-cols-2 v-tablet:gap-y-4">
        <div className="flex flex-col gap-2 v-tablet:pl-0 v-tablet:col-span-2 v-tablet:pb-4 border-r v-tablet:border-r-0 v-tablet:border-b">
          <div className="flex gap-2 items-end">
            <p className="text-sc text-gray-600 uppercase">Debited Days</p>
            <p className="font-semibold text-M">
              {props.item.paymentDays - props.item.daysRemaining}/{props.item.paymentDays}
            </p>
          </div>
          <div>
            <Progress
              className="mr-4 v-tablet:mr-0"
              size={"md"}
              value={(1 - props.item.daysRemaining / props.item.paymentDays) * 100}
            />
            <div className="flex justify-between mr-4 v-tablet:mr-0 text-L">
              <p>0%</p> <p>100%</p>
            </div>
          </div>
        </div>
        <PairContainer>
          <div className="flex flex-col gap-2 v-tablet:col-span-2 v-tablet:pb-4 border-r v-tablet:border-r-0 pr-2 justify-center">
            <div className="flex gap-2 items-end">
              <p className="text-sc text-gray-600 uppercase">Interval</p>
              <p className="font-semibold text-M">{props.item.agreement.paymentInterval}</p>
            </div>
            <div className="flex gap-2 items-end whitespace-nowrap">
              <p className="text-sc text-gray-600 uppercase">ID </p>
              <p className="semibold text-S text-gray-500 truncate">{props.item.agreement.id}</p>
            </div>
          </div>
          <div className="flex flex-col justify-end mb-[10px] items-start v-tablet:col-span-2 v-tablet:p-0 v-tablet:mb-0 v-tablet:pt-2">
            {props.item.paymentDays > 0 && (
              <LinkButton
                label="Export Payouts (.xlsx)"
                icon={<Download />}
                onClick={doDownloadInvoice}
                size="text-M"
              />
            )}
          </div>
        </PairContainer>
      </div>
    </div>
  );
}

function ProductTrackerBody(props: { item: ProductTrackerAgreementResult; isRetailer: boolean }) {
  return (
    <div className="py-4 grid grid-cols-4 w-full gap-y-6 v-tablet:gap-y-4 v-tablet:grid-cols-2">
      <PairRowContainer>
        <BodyItem label="Company" value={props.item.agreement.retailer} />
        <BodyItem label="Started" value={props.item.agreement.startDate} />
      </PairRowContainer>

      <div className="col-span-2 grid grid-cols-1 items-end">
        <div className="flex flex-col gap-2 pl-4 v-tablet:pl-0 v-tablet:col-span-2 v-tablet:pb-4">
          <div className="flex gap-2 items-end whitespace-nowrap">
            <p className="text-sc text-gray-600 uppercase">ID </p>
            <p className="semibold text-M text-gray-500 truncate">{props.item.agreement.id}</p>
          </div>
        </div>
      </div>

      <div className="col-span-4 grid grid-cols-2 v-tablet:gap-4">
        <PairContainer>
          <BodyItem label="Show Sell Price" value={props.item.showProductTrackerSellPrice ? "YES" : "NO"} />
        </PairContainer>
      </div>
    </div>
  );
}

function PairContainer(props: { children: JSX.Element | JSX.Element[] | never[]; drawBorder?: boolean }) {
  return (
    <div className="v-tablet:flex-row grow flex-col gap-4 flex border-r border-gray-300 first:ml-0 v-tablet:ml-0 ml-4 last-of-type:border-r-0 [&:nth-child(4)]:border-r-0 v-tablet:border-r-0 last:!border-b-0 v-tablet:border-b v-tablet:gap-0 col-span-1 v-tablet:col-span-4 v-tablet:pb-4 last:pb-0 grid grid-cols-2">
      {props.children}
    </div>
  );
}

function PairRowContainer(props: { children: JSX.Element | JSX.Element[] | never[] }) {
  return (
    <div className="flex-row grow gap-6 flex first:border-r border-gray-300 first:ml-0 v-tablet:ml-0 ml-4 v-tablet:!border-r-0 v-tablet:border-b v-tablet:gap-0 col-span-2 v-tablet:col-span-4 v-tablet:pb-4 grid grid-cols-4 v-tablet:grid-cols-2">
      {props.children}
    </div>
  );
}

function BodyItem(props: { label: string; value: string }) {
  return (
    <div className="flex flex-col gap-1 col-span-2 v-tablet:col-span-1">
      <p className="text-sc text-gray-600 uppercase">{props.label}</p>
      <p className="text-M text-gray-900 font-semibold">{props.value}</p>
    </div>
  );
}

function StatusDropdown(props: { filter: AgreementFilter; setFilter: (filter: AgreementFilter) => void }) {
  const items = props.filter.getItems("status", true);

  return (
    <Checkbox.Group
      className="flex flex-col gap-2"
      value={items.map(i => i.value)}
      label="Status"
      onChange={values => {
        const items = values.map(v => {
          return { label: v, value: v, active: true };
        });

        props.setFilter(props.filter.overwrite("status", items));
      }}
    >
      <Checkbox styles={dropdownStyling} label="Inactive" value="Inactive" />
      <Checkbox styles={dropdownStyling} label="Active" value="Active" />
    </Checkbox.Group>
  );
}

function AgreementTypeDropdown(props: { filter: AgreementFilter; setFilter: (filter: AgreementFilter) => void }) {
  const items = props.filter.getItems("agreementType", true);

  return (
    <Checkbox.Group
      className="flex flex-col gap-2"
      value={items.map(i => i.value)}
      label="Agreement Types"
      onChange={values => {
        const items = values.map(v => {
          return { label: v.replace("tT", "t T"), value: v, active: true };
        });

        props.setFilter(props.filter.overwrite("agreementType", items));
      }}
    >
      <Checkbox styles={dropdownStyling} label="Split" value="Split" />
      <Checkbox styles={dropdownStyling} label="Wholesale" value="Wholesale" />
      <Checkbox styles={dropdownStyling} label="Invoice" value="Invoice" />
      <Checkbox styles={dropdownStyling} label="Product Tracker" value="ProductTracker" />
    </Checkbox.Group>
  );
}

function BrandDropdown(props: {
  filter: AgreementFilter;
  setFilter: (filter: AgreementFilter) => void;
  uniqueBrands: string[];
}) {
  return (
    <MultiSelect
      styles={dropdownStyling}
      placeholder="Brands"
      label="Brand"
      data={props.uniqueBrands}
      value={props.filter.getItems("brands", true).map(b => b.value)}
      maxDropdownHeight={250}
      searchable
      limit={20}
      dropdownComponent="div"
      onChange={values => {
        const items = values.map(v => {
          return { label: v, value: v, active: true };
        });

        props.setFilter(props.filter.overwrite("brands", items));
      }}
    />
  );
}

export function getAgreementResult(
  search: string,
  agreementItems: { agreement: AgreementResult }[],
  searchResult: Fuse.FuseResult<ResultItem>[],
): Result {
  const searchString = search.trim();
  const agreementWithId = agreementItems?.filter(a => a.agreement.id === searchString);
  if (agreementWithId?.length === 1) {
    return [agreementWithId[0]] as ResultItem[];
  }

  const matchingInvoices = agreementItems?.filter(a => "invoiceNo" in a && a.invoiceNo === searchString) ?? [];

  if (matchingInvoices.length > 0) {
    return [matchingInvoices[0]] as ResultItem[];
  }

  if (searchString.length > 0 && searchResult) {
    // Fuzzy search
    return searchResult.map(i => i.item);
  } else if (agreementItems) {
    // Return whole list
    return agreementItems.slice().sort(function (a, b) {
      return Date.parse(b.agreement.startDate) - Date.parse(a.agreement.startDate);
    }) as ResultItem[];
  }

  return [];
}
