const Paths = {
  payment: {
    payoutReport: "/payout-report",
    clientFundsAccount: "/payout-report/client-funds-account",
  },
  agreements: {
    partners: "/partners",
    partner: "/partners/:id",
    create: "/partners/create",
  },
  withdrawals: {
    withdrawalReport: "/withdrawals",
    vatReport: "/withdrawals/vat-report",
    clientFundsAccount: "/withdrawals/client-funds-account",
  },
  productTracker: {
    individualStocks: "/product-tracker",
    deliveries: "/product-tracker/deliveries",
    styleCatalog: "/product-tracker/style-catalog",
  },
  articles: {
    upload: "/articles/upload",
    download: "/articles/download",
    articles: "/articles",
    articleEvents: "/articles/article-events",
  },
  fortnox: {
    callback: "/fortnox/callback",
  },
  sitoo: {
    connection: "/sitoo/connection",
  },
  shopify: {
    callback: "/shopify/callback",
  },
  preferences: {
    index: "/preferences",
    users: "/preferences/users",
    overview: "/preferences/overview",
    stores: "/preferences/stores",
    inventories: "/preferences/inventories",
    helpdesk: "https://helpdesk.impulso.se/en/",
    userPrefs: "/user",
    missingArticles: "/preferences/missing-articles",
  },
  signup: {
    invitation: "/invitation",
  },
};

export default Paths;
