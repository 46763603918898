import React from "react";

// Packages imports
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { MantineProvider, ColorSchemeProvider } from "@mantine/core";
import { Provider } from "react-redux";
import { Route, Routes } from "react-router-dom";

// Components imports
import { App } from "./App";
import Paths from "./configuration/Paths";

// Backbone imports
import { GlobalAppsettings } from "./common/GlobalSettings";
import { datadogRum } from "@datadog/browser-rum";

// Styles imports
import "./styles/preflight.css";
import "./styles/index.css";
import "./styles/fonts.css";

import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/eb-garamond/400.css";
import "@fontsource/eb-garamond/500.css";
import "@fontsource/eb-garamond/600.css";

import { Auth0Provider } from "@auth0/auth0-react";

import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as enLang from "../i18n/en.json";

import { colors } from "@impulso/common/Theme";
import { FullscreenLoader } from "@impulso/common/components/Loader";

const SignUp = React.lazy(() => import(/* webpackChunkName: 'SignUp' */ "./pages/signup/Invitation"));

const colorScheme = "light";

if (GlobalAppsettings.RumAppId && GlobalAppsettings.RumClienToken) {
  datadogRum.init({
    version: GlobalAppsettings.Version,
    applicationId: GlobalAppsettings.RumAppId,
    clientToken: GlobalAppsettings.RumClienToken,
    site: "datadoghq.eu",
    service: "portal",
    env: GlobalAppsettings.RumEnv,
    sampleRate: 100,
    sessionReplaySampleRate: 0,
    trackInteractions: false,
    trackUserInteractions: false,
    trackResources: true,
    trackLongTasks: false,
    defaultPrivacyLevel: "mask",
    allowedTracingOrigins: [GlobalAppsettings.BaseUrl],
  });
  // datadogRum.startSessionReplayRecording();
}

i18n
  // .use(HttpApi)
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: "en",
    resources: {
      en: { translation: enLang }, // Bundle english translations with app instead of loading them lazily
    },
    debug: GlobalAppsettings.IsDevelopment,
  })
  .then(() => console.log("i18next init complete"))
  .catch(e => console.error(e));

const rootElement = document.getElementById("root");

if (!rootElement) {
  throw new Error("No root element found");
}

const root = createRoot(rootElement);
function shouldSkipAuth0Callback(): boolean {
  const whitelist = ["/fortnox/callback", "/shopify/callback"];
  return whitelist.includes(window.location.pathname);
}
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="auth0.impulso.se"
      clientId="nOLZUeuR3XJflZfbIflmFltwLsxmYSWU"
      redirectUri={window.location.origin}
      audience="https://api.portal.impulso.com"
      scope="read:user"
      cacheLocation="localstorage"
      skipRedirectCallback={shouldSkipAuth0Callback()}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={() => {}}>
            <MantineProvider
              theme={{
                colorScheme: colorScheme,
                fontFamily: "Inter, Kumbh Sans",
                fontFamilyMonospace: "Monaco, Courier, monospace",
                headings: { fontFamily: "EB Garamond" },
                black: "#454545",
                loader: "bars",
                colors: {
                  brand: [
                    colors.brand[50],
                    colors.brand[100],
                    colors.brand[200],
                    colors.brand[300],
                    colors.brand[400],
                    colors.brand[500],
                    colors.brand[600],
                    colors.brand[700],
                    colors.brand[800],
                    colors.brand[900],
                  ],
                },
                primaryColor: "brand",
              }}
            >
              <BrowserRouter>
                <Routes>
                  <Route
                    path={Paths.signup.invitation}
                    element={
                      <React.Suspense fallback={<FullscreenLoader message="Loading" />}>
                        <SignUp />
                      </React.Suspense>
                    }
                  />
                  <Route path="*" element={<App />} />
                </Routes>
              </BrowserRouter>
            </MantineProvider>
          </ColorSchemeProvider>
        </PersistGate>
      </Provider>
    </Auth0Provider>
  </React.StrictMode>,
);
