export default function PaymentWithdrawals() {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 17.0001V5.28857H19V17.0001H2Z" stroke="currentcolor" strokeWidth="2" />
      <path d="M5 20.0001H22V8.28857" stroke="currentcolor" strokeWidth="2" />
      <path
        d="M10.5 13.5C11.8807 13.5 13 12.3807 13 11C13 9.61929 11.8807 8.5 10.5 8.5C9.11929 8.5 8 9.61929 8 11C8 12.3807 9.11929 13.5 10.5 13.5Z"
        stroke="currentcolor"
        strokeWidth="2"
      />
    </svg>
  );
}
