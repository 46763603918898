import { IconProps, iconSizes } from "./common";

export default function Notification(props: IconProps) {
  const size = iconSizes[props.size ?? "small"];
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 9V14L3 18H21L19 14V9C19 5.13401 15.866 2 12 2C8.13401 2 5 5.13401 5 9Z"
        stroke="currentcolor"
        strokeWidth="2"
      />
      <path d="M10 22H14" stroke="currentcolor" strokeWidth="2" />
    </svg>
  );
}
