export default function Info() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.00001 16.4286C13.1027 16.4286 16.4286 13.1027 16.4286 8.99998C16.4286 4.8973 13.1027 1.57141 9.00001 1.57141C4.89733 1.57141 1.57144 4.8973 1.57144 8.99998C1.57144 13.1027 4.89733 16.4286 9.00001 16.4286Z"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 9V13" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M9.00002 6.71427C9.31561 6.71427 9.57145 6.45843 9.57145 6.14284C9.57145 5.82725 9.31561 5.57141 9.00002 5.57141C8.68443 5.57141 8.42859 5.82725 8.42859 6.14284C8.42859 6.45843 8.68443 6.71427 9.00002 6.71427Z"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
