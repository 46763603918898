import { hasModule, useOrganisation } from "../../security/UseGlobalSecurity";
import { useGetFortnoxConnectionStatusQuery, useGetShopifyConnectionStatusQuery } from "../../../api/IntegrationsApi";
import { Organisation } from "../../../api/models/UserProfile";
import { Counter, useNavigationConfig } from "../../../configuration/Navigation";
import { useVTabletSizeDetect } from "@impulso/common/hooks/useMobileDetect";
import { NavigationSidebarView } from "@impulso/common/components/NavigationSidebarView";
import { colors } from "@impulso/common/Theme";
import { GlobalAppsettings } from "src/common/GlobalSettings";

export type NavigationSidebarProps = {
  onLogoutClick: () => void;
  isTablet?: boolean;
};

export default function NavigationSidebar(props: NavigationSidebarProps) {
  const { isVTabletSize, initialized } = useVTabletSizeDetect();
  const organisation = useOrganisation();
  const modules = organisation?.modules.map(m => m.identifier);
  const role = organisation?.role;
  const navigationConfig = useNavigationConfig(modules ?? [], role ?? "Member");
  const counters: Record<Counter, number | boolean | undefined> = {
    settings: useSettingsHasNotice(organisation),
  };

  if (!initialized) return <></>;

  return (
    <NavigationSidebarView
      config={navigationConfig}
      counters={counters}
      onLogoutClick={props.onLogoutClick}
      isTablet={isVTabletSize}
      logoColor={colors.brand.DEFAULT}
      impulsoVersion={GlobalAppsettings.ImpulsoVersion}
    />
  );
}

function useSettingsHasNotice(organisation?: Organisation): boolean | undefined {
  // Add more integration counters here
  const fortnox = useFortnoxConnectionHasNotice(organisation);
  const shopify = useShopifyConnectionHasNotice(organisation);

  return fortnox || shopify;
}

function useFortnoxConnectionHasNotice(organisation?: Organisation): boolean | undefined {
  const hasFortnoxModule = hasModule(organisation, "impulso.retailer.fortnox.initiate");
  const isAdmin = organisation && organisation.role === "Administrator";

  const shouldFetch = hasFortnoxModule && organisation && isAdmin;

  const { currentData, error } = useGetFortnoxConnectionStatusQuery(
    { organisationId: organisation?.id! },
    { skip: !shouldFetch },
  );

  if (!shouldFetch) {
    return undefined;
  }

  if (error) {
    return false;
  }

  return currentData && currentData.status !== "Connected";
}

function useShopifyConnectionHasNotice(organisation?: Organisation): boolean | undefined {
  const hasShopifyModule = hasModule(organisation, "impulso.retailer.shopify.initiate");
  const isAdmin = organisation && organisation.role === "Administrator";

  const shouldFetch = hasShopifyModule && organisation && isAdmin;

  const { currentData, error } = useGetShopifyConnectionStatusQuery(
    { organisationId: organisation?.id! },
    { skip: !shouldFetch },
  );

  if (!shouldFetch) {
    return undefined;
  }

  if (error) {
    return false;
  }

  return currentData && currentData.status !== "Connected";
}
