import DownChevronIcon from "@impulso/common/Icons/DownChevronIcon";
import Search from "@impulso/common/Icons/Search";
import UpChevronIcon from "@impulso/common/Icons/UpChevronIcon";
import { OnClickOutside } from "@impulso/common/common/utilities/OnClickoutside";
import { dropdownStyling } from "@impulso/common/styling/DropdownStyling";
import { Loader, ScrollArea, TextInput } from "@mantine/core";
import { useCallback, useRef, useState } from "react";
import { OrganisationId } from "src/api/models/UserProfile";

type OrganisationSelectorProps = {
  organisations: { id: string; name: string }[];
  currentOrganisationId?: OrganisationId;
  setCurrentOrganisationId: (id: OrganisationId) => void;
  organisationLoading: boolean;
};

export function OrganisationSelector(props: OrganisationSelectorProps) {
  let organisations = props.organisations;
  const organisation = !props.currentOrganisationId
    ? undefined
    : (props.organisations.find(o => o.id === props.currentOrganisationId) ?? props.organisations[0]);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const wrapperRef = useRef(null);
  OnClickOutside(
    wrapperRef,
    useCallback(() => setOpen(false), []),
  );

  const disabled = props.organisationLoading;
  const canSearch = organisations.length >= 8;

  if (canSearch && (search?.length ?? 0) >= 2) {
    organisations = organisations.filter(o => o.name.toLowerCase().includes(search?.toLowerCase() ?? ""));
  }

  const bgCol = props.organisationLoading ? "bg-gray-200" : "bg-white";
  const hoverStyle = !disabled ? "hover:bg-gray-100 hover:cursor-pointer" : "";
  const activeStyle = !disabled ? "active:bg-gray-200 hover:cursor-pointer" : "";

  return (
    <div className="relative text-xs" ref={wrapperRef}>
      <div
        className={`flex items-center border pr-4 border-black select-none rounded-full ${bgCol} ${hoverStyle} ${activeStyle}`}
        onClick={() => {
          !disabled && setOpen(!open);
        }}
      >
        <p className="p-2 pl-4">{organisation?.name ?? "Partners"}</p>
        {props.organisationLoading ? <Loader size={14} /> : open ? <UpChevronIcon /> : <DownChevronIcon />}
      </div>
      <div
        className={`absolute top-[42px] left-0 ${open ? "" : "scale-y-0 opacity-0"} origin-top bg-white drop-shadow-md z-20 transition-all`}
      >
        {canSearch && (
          <TextInput
            label=""
            placeholder="Search..."
            required={false}
            size="sm"
            styles={dropdownStyling}
            rightSection={<Search />}
            value={search}
            onChange={event => setSearch(event.currentTarget.value)}
          />
        )}
        <ScrollArea h={organisations.length * 40 >= 320 ? 320 : organisations.length * 40}>
          {organisations.map(item => (
            <div
              key={item.id}
              className={`w-[275px] p-3 px-4 flex gap-2 ${hoverStyle}`}
              onClick={() => {
                props.setCurrentOrganisationId(item.id as OrganisationId);
                setSearch("");
                setOpen(false);
              }}
            >
              <p className="truncate">{item.name}</p>
            </div>
          ))}
        </ScrollArea>
      </div>
    </div>
  );
}
