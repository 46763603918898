import { ScrollArea } from "@mantine/core";
import { parse } from "date-fns/parse";
import {
  NotificationMeta,
  NotificationStatus,
  NotificationType,
  OrganisationNotificationResponse,
} from "../../api/NotificationApi";
import { NotificationRow } from "./NotificationRow";

export type TabValue = "inbox" | "complete";
interface ListProps {
  notifications: OrganisationNotification[];
  onClose: () => void;
}
export function NotificationList({ notifications, onClose }: ListProps) {
  return (
    <ScrollArea offsetScrollbars>
      <div className="overflow-auto">
        {notifications.map(notification => (
          <NotificationRow key={notification.id} notification={notification} onClose={onClose} />
        ))}
      </div>
    </ScrollArea>
  );
}

export function notificationFilterFunc(tab: TabValue) {
  return tab === "inbox"
    ? (notification: OrganisationNotification) => notification.status === "Pending" || notification.status === "Unread"
    : (notification: OrganisationNotification) =>
        notification.status === "CompletedBySystem" || notification.status === "CompletedByUser";
}

export interface OrganisationNotification {
  id: string;
  status: NotificationStatus;
  type: NotificationType;
  created: Date;
  meta: NotificationMeta;
}

export function networkToClient(notification: OrganisationNotificationResponse): OrganisationNotification {
  return {
    ...notification,
    created: parse(notification.created, "yyyy-MM-dd", new Date()),
    meta: JSON.parse(notification.meta),
  };
}
