import { faCircleUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LinkButton } from "@impulso/common/components/link";
import NotificationIcon from "@impulso/common/Icons/Notification";
import XMark from "@impulso/common/Icons/XMark";
import CustomSelectElement from "@impulso/common/src/components/buttons/CustomDropdown";
import { colors } from "@impulso/common/Theme";
import { Avatar, Divider, Indicator, Tooltip } from "@mantine/core";
import { minutesToMilliseconds } from "date-fns/minutesToMilliseconds";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserResponse, useGetUserQuery } from "src/api/UserApi";
import Paths from "src/configuration/Paths";
import { OrganisationId } from "../../../api/models/UserProfile";
import { useGetNotificationsQuery } from "../../../api/NotificationApi";
import { useHasModule, useOrganisationId } from "../../security/UseGlobalSecurity";
import { OrganisationSelector } from "../dropdown/OrganisationSelect";

export type HeaderProps = {
  organisations: { id: OrganisationId; name: string; defaultCurrency: string }[];
  currentOrganisationId: OrganisationId;
  setCurrentOrganisationId: (id: OrganisationId) => void;
  organisationLoading: boolean;
  onLogoutClick: () => void;
  showNotifications: () => void;
};

export default function Header(props: HeaderProps) {
  const organisationId = useOrganisationId();
  const { data: user } = useGetUserQuery(undefined);
  const hasNotifications = useHasModule("impulso.notifications.view");

  const { data: notifications } = useGetNotificationsQuery(
    { organisationId: organisationId! },
    {
      skip: !hasNotifications,
      pollingInterval: minutesToMilliseconds(5),
    },
  );

  return (
    <HeaderView
      {...props}
      userResponse={user!}
      hasNotifications={hasNotifications}
      notifications={notifications?.slice().filter(n => n.status === "Unread").length}
    />
  );
}

export type HeaderViewProps = {
  hasNotifications: boolean;
  notifications?: number;
  userResponse: UserResponse;
} & HeaderProps;

export function HeaderView(props: HeaderViewProps) {
  const [profileOpen, setProfileOpen] = useState(false);
  const organisations = props.organisations.slice();
  organisations.sort((a, b) => {
    if (a.name.toUpperCase() < b.name.toUpperCase()) {
      return -1;
    }
    if (a.name.toUpperCase() > b.name.toUpperCase()) {
      return 1;
    }
    return 0;
  });
  const Picture = () => {
    const initials = toInitials(props.userResponse.user.name);
    if (initials) {
      return (
        <Avatar
          alt={props.userResponse.user.email}
          className="inline-block align-middle m-0"
          styles={{ placeholder: { color: "white", backgroundColor: colors.brand[400], fontWeight: 400 } }}
          size={34}
          radius="xl"
        >
          {initials}
        </Avatar>
      );
    }

    return <FontAwesomeIcon icon={faCircleUser} className="h-[34px] inline align-middle text-brand-400" />;
  };

  return (
    <div id="header" className="flex flex-col p-6 pb-0 gap-6 auto-rows-min">
      <div className="flex flex-row justify-between items-center">
        {props.currentOrganisationId ? (
          <div className="flex gap-4">
            <CustomSelectElement
              body={
                <UserProfileDropdown
                  userResponse={props.userResponse}
                  picture={<Picture />}
                  setOpen={setProfileOpen}
                  onLogoutClick={props.onLogoutClick}
                />
              }
              open={profileOpen}
              setOpen={setProfileOpen}
            >
              <Picture />
              <div
                className={`${profileOpen ? "" : "opacity-0 scale-0"} rounded-full bg-white border border-black hover:bg-gray-200 absolute inset-0 flex items-center justify-center transition-all aspect-square`}
              >
                <XMark />
              </div>
            </CustomSelectElement>
            <Divider orientation="vertical" />
            <OrganisationSelector
              organisations={organisations}
              currentOrganisationId={props.currentOrganisationId}
              setCurrentOrganisationId={props.setCurrentOrganisationId}
              organisationLoading={props.organisationLoading}
            />
          </div>
        ) : (
          <></>
        )}
        <div>
          <NotificationButton
            hasNotifications={props.hasNotifications}
            notifications={props.notifications}
            showNotifications={props.showNotifications}
          />
        </div>
      </div>
    </div>
  );
}

function UserProfileDropdown(props: {
  userResponse: UserResponse;
  picture: JSX.Element;
  setOpen: (value: boolean) => void;
  onLogoutClick: () => void;
}) {
  const user = props.userResponse.user;
  const navigate = useNavigate();

  const location = useLocation();

  return (
    <div className="p-2 border border-gray-200 w-[240px]">
      <div className="flex gap-2 items-center">
        <div>
          <p className="text-l truncate">{user.name}</p>
          <p className="text-S truncate text-gray-600">{user.email}</p>
        </div>
      </div>
      <div className="text-sc text-gray-600 mt-2">
        {user.isDev ? <span className="text-brand-400">DEVELOPER</span> : <></>}
      </div>
      <div className="border-t border-gray-400 w-full my-2" />
      <div className="flex flex-col gap-1">
        <LinkButton
          label="Edit Profile"
          underlined={false}
          disabled={location.pathname === Paths.preferences.userPrefs}
          onClick={() => {
            props.setOpen(false);
            navigate(Paths.preferences.userPrefs);
          }}
          size="w-full !justify-start"
        />
        <LinkButton
          label="Log out"
          underlined={false}
          onClick={() => {
            props.setOpen(false);
            props.onLogoutClick();
          }}
          size="w-full !justify-start"
        />
      </div>
    </div>
  );
}

function NotificationButton(props: {
  hasNotifications: boolean;
  notifications?: number;
  showNotifications: () => void;
}) {
  const notificationCount = props.notifications ?? 0;
  const count = notificationCount > 9 ? "9+" : notificationCount.toFixed();

  if (!props.hasNotifications) {
    return null;
  }

  return (
    <Tooltip position="left" label={"Notifications"} withArrow>
      <div
        className="p-1 rounded-full hover:bg-gray-200 active:bg-gray-400 hover:cursor-pointer"
        onClick={props.showNotifications}
      >
        <Indicator
          disabled={notificationCount === 0}
          size={16}
          label={count}
          radius="md"
          styles={{ indicator: { fontSize: "10px", fontWeight: "normal" } }}
        >
          <NotificationIcon size="large" />
        </Indicator>
      </div>
    </Tooltip>
  );
}

function toInitials(name?: string): string | undefined {
  if (name && name.match(/.+@.+/)) {
    return emailToInitials(name);
  }

  const initials =
    name
      ?.trim()
      .split(" ")
      .map(a => a[0]?.toUpperCase()) ?? [];

  if (initials.length === 0) {
    return undefined;
  } else if (initials.length === 1) {
    return initials[0];
  } else {
    return initials[0] + initials[initials.length - 1];
  }
}

function emailToInitials(email?: string) {
  const name = (email?.split("@") ?? [])[0];
  const initials = name?.split(".").map(a => a[0].toUpperCase());

  if (initials.length === 0) {
    return undefined;
  } else if (initials.length === 1) {
    return initials[0];
  } else {
    return initials[0] + initials[initials.length - 1];
  }
}
