import { DashFilter } from "src/pages/new/Dashboard";
import { MultiSelectElement } from "./MultiSelect";
import { Organisation } from "src/api/models/UserProfile";
import { useGetSupplierSeasonQuery } from "src/api/ArticlesApi";

export type SeasonSelectProps = {
  organisation: Organisation;
  filter: DashFilter;
  setFilter: (filter: DashFilter) => void;
};

export function SeasonSelectElement(props: SeasonSelectProps) {
  const { data: seasons, isFetching: isFetchingSeasons } = useGetSupplierSeasonQuery(
    { organisationId: props.organisation!.id },
    { skip: !props.organisation },
  );
  const seasonItems = seasons?.map(season => {
    return {
      value: season.id,
      label: season.name,
      shortLabel: season.shortName,
    };
  });

  return (
    <MultiSelectElement
      filterKey={"seasons"}
      title={"Seasons"}
      filter={props.filter}
      setFilter={props.setFilter}
      isDisabled={isFetchingSeasons}
      items={seasonItems ?? []}
      organisation={props.organisation!}
    />
  );
}
