import { portalApi } from "./base/PortalApi";
import { Result as AgreementResult, AgreementType, PaymentInterval } from "../modules/agreements/api/Agreement";
import { OrganisationId } from "./models/UserProfile";

export const agreementApi = portalApi.injectEndpoints({
  endpoints: builder => ({
    getAgreements: builder.query<AgreementResult, { organisationId: OrganisationId }>({
      query: params => `/api/agreement/getmyagreements?organisationId=${params.organisationId}`,
      providesTags: ["agreements"],
    }),
    getSupplierInformation: builder.query<OrganisationInformationResponse, { organisationId: OrganisationId }>({
      query: params => `/api/agreement/GetSupplierInformation?organisationId=${params.organisationId}`,
      providesTags: ["agreements"],
    }),
    getArticleSuppliers: builder.query<OrgInformation[], { organisationId: OrganisationId }>({
      query: params => `/api/agreement/GetArticleSuppliers?organisationId=${params.organisationId}`,
      providesTags: ["agreements"],
    }),
    getRetailerInformation: builder.query<OrganisationInformationResponse, { organisationId: OrganisationId }>({
      query: params => `/api/agreement/GetRetailerInformation?organisationId=${params.organisationId}`,
      providesTags: ["agreements"],
    }),
    getImpulsoAgreement: builder.query<ImpulsoAgreementResponse, { organisationId: OrganisationId }>({
      query: params => `/api/agreement/GetImpulsoAgreement?organisationId=${params.organisationId}`,
    }),
    hasPtShowSellPriceAgreement: builder.query<boolean, { organisationId: OrganisationId }>({
      query: params => `/api/agreement/HasPtShowSellPriceAgreement?organisationId=${params.organisationId}`,
    }),
    /** Create *************************************************************************************** Create **/

    createAgreement: builder.mutation<CreateAgreementResponse, CreateAgreementRequest>({
      query: ({ query, body }) => ({
        url: `/api/agreement/CreateAgreement?organisationId=${query.organisationId}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["agreements"],
    }),

    validateAgreement: builder.query<ValidationResult, CreateAgreementRequest>({
      query: ({ query, body }) => ({
        url: `/api/agreement/ValidateAgreement?organisationId=${query.organisationId}`,
        method: "POST",
        body,
      }),
      providesTags: ["agreements"],
    }),
  }),
});

export interface PaymentAgreementRequest {
  retailerFeePercent: number;
  supplierFeePercent: number;
  supplierSplitPercent?: number;
  paymentInterval: PaymentInterval;
  includeVatInPayout: boolean;
}

export interface InvoiceAgreementRequest {
  invoiceNo: string;
  termsOfPaymentDays: number;
  totalAmountInclVat: number;
}

export interface ProductTrackerAgreementRequest {
  showProductTrackerSellPrice: boolean;
}

export interface CreateAgreementRequest {
  query: {
    organisationId: OrganisationId;
  };
  body: {
    supplierId?: OrganisationId;
    supplierName: string;
    retailerId?: OrganisationId;
    retailerName: string;
    storeId?: OrganisationId;
    storeName?: string;

    agreementType: AgreementType;
    brands: BrandAndAliases[];

    startDate: string;
    description: string;

    payment?: PaymentAgreementRequest;
    invoice?: InvoiceAgreementRequest;
    productTracker?: ProductTrackerAgreementRequest;
  };
}

export interface CreateAgreementResponse {
  name: string;
  startDate: string;
  type: AgreementType;
}

export interface ValidationResult {
  warnings: AgreementValidationResult.Warning[];
  errors: AgreementValidationResult.Error[];
}

export namespace AgreementValidationResult {
  export type Warning =
    | "DuplicateInvoiceNo"
    | "BrandIsCoveredByAnotherActiveAgreement"
    | "MissingBrandAndStore"
    | "MissingBrandAliasAndStore";
  export type Error = "NullRequest" | "InvalidFeeSplit" | "InvalidPaymentInterval" | "SplitRetailerNotRoot";
}

export interface OrganisationInformationResponse {
  organisations: {
    id: string;
    name: string;
    brands: string[];
    stores: OrgInformation[];
    isRealEstate: boolean;
  }[];
  autoPaymentFeePercent?: number;
}
export interface StoreInformationResponse {
  Stores: OrgInformation[];
}
export interface OrgInformation {
  id: string;
  name: string;
}

export interface BrandAndAliases {
  brandName: string;
  aliases: string[];
}

export interface ImpulsoAgreementResponse {
  registeredAt: string;
  startDate: string;
  endDate: string;
  hasAutoPayment: boolean;
  hasProductTracker: boolean;
  volumeFeePlans?: VolumeFeePlan[];
  autoPaymentFeePercent?: number;
  fileAttachments: FileAttachmentResponse[];
}

export interface VolumeFeePlan {
  fromDate: string;
  toDate: string;
  isActive: boolean;
  feeSteps: VolumeFeeStep[];
}

export interface VolumeFeeStep {
  fromVolume: number;
  wholesaleFee: number;
}

export interface FileAttachmentResponse {
  fileName: string;
  fileId: string;
}

export const {
  useGetAgreementsQuery,
  useGetSupplierInformationQuery,
  useGetArticleSuppliersQuery,
  useGetRetailerInformationQuery,
  useGetImpulsoAgreementQuery,
  useCreateAgreementMutation,
  useLazyValidateAgreementQuery,
  useHasPtShowSellPriceAgreementQuery,
} = agreementApi;
