export default function Search() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_393_14472)">
        <path
          d="M7.33337 13.3333C10.6471 13.3333 13.3334 10.647 13.3334 7.33331C13.3334 4.0196 10.6471 1.33331 7.33337 1.33331C4.01967 1.33331 1.33337 4.0196 1.33337 7.33331C1.33337 10.647 4.01967 13.3333 7.33337 13.3333Z"
          stroke="currentcolor"
          strokeWidth="1.5"
        />
        <path d="M11.6051 11.6048L14.6667 14.6667" stroke="currentcolor" strokeWidth="1.5" />
      </g>
      <defs>
        <clipPath id="clip0_393_14472">
          <rect width="16" height="16" fill="none" />
        </clipPath>
      </defs>
    </svg>
  );
}
