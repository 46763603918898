import React from "react";

export function CheckMarkIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_226_4820)">
        <rect width="24" height="24" fill="#FF6900" />
        <path
          d="M4 11.9988L8.87308 16.7596C8.946 16.8355 9.03407 16.896 9.13185 16.9373C9.22963 16.9787 9.33506 17 9.44161 17C9.54816 17 9.65358 16.9787 9.75137 16.9373C9.84915 16.896 9.93722 16.8355 10.0101 16.7596L20 7"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_226_4820">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
