/**!
 * @file This file contains functions for downloading files, which should not be cached in browser by RTK Query, but downloaded directly to filesystem.
 */

import { DatesRangeValue } from "@mantine/dates";
import { addDays } from "date-fns/addDays";
import { format } from "date-fns/format";
import { GlobalAppsettings } from "../common/GlobalSettings";
import { WithdrawItemStatus } from "../modules/withdrawals/api/withdrawal-api";
import { SortDirection } from "./Models";
import { OrganisationId } from "./models/UserProfile";
export async function downloadAgreementAttachment(
  accessToken: string,
  fileName: string,
  fileId: string,
  organisationId: OrganisationId,
) {
  try {
    const url = `/api/agreement/GetFileAttachment?organisationId=${organisationId}&fileId=${fileId}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, fileName);
  } catch (e) {
    console.error("Could not get requested attachment", e);
  }
}
export async function downloadSupplierSalesBreakdown(
  organisationId: OrganisationId,
  interval: DatesRangeValue,
  accessToken: string,
  filterQuery: string,
) {
  try {
    const startDate = format(interval[0]!, "yyyy-MM-dd");
    const endDate = format(interval[1]!, "yyyy-MM-dd");
    const url = `/api/sales/GetSupplierSalesDetails?organisationId=${organisationId}&startDate=${startDate}&endDate=${endDate}${filterQuery.length <= 0 ? "" : "&" + filterQuery}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, "sales-data.xlsx");
  } catch (e) {
    console.error("Could not get sales breakdown", e);
  }
}

export async function downloadProductTrackerSellPrice(
  organisationId: OrganisationId,
  interval: DatesRangeValue,
  accessToken: string,
  filterQuery: string,
) {
  try {
    const startDate = format(interval[0]!, "yyyy-MM-dd");
    const endDate = format(addDays(interval[1]!, 1), "yyyy-MM-dd");
    const url = `/api/sales/GetProductTrackerTotalSellPriceBreakdown?organisationId=${organisationId}&startDate=${startDate}&endDate=${endDate}${filterQuery.length <= 0 ? "" : "&" + filterQuery}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, "sell-price-data.xlsx");
  } catch (e) {
    console.error("Could not get sell price breakdown", e);
  }
}

export async function downloadWholesaleBreakdown(
  organisationId: OrganisationId,
  interval: DatesRangeValue,
  accessToken: string,
  filterQuery: string,
) {
  try {
    const startDate = format(interval[0]!, "yyyy-MM-dd");
    const endDate = format(addDays(interval[1]!, 1), "yyyy-MM-dd");
    const url = `/report/stock/GetExcelOfTotalWholesale?organisationId=${organisationId}&startDate=${startDate}&endDate=${endDate}${filterQuery.length <= 0 ? "" : "&" + filterQuery}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, "sales-data.xlsx");
  } catch (e) {
    console.error("Could not get wholesale breakdown", e);
  }
}

export async function downloadSalesSpecification(
  organisationId: OrganisationId,
  retailerId: OrganisationId,
  payoutId: string,
  accessToken: string,
) {
  try {
    const url = `/api/sales/GetSalesSpecification?organisationId=${organisationId}&retailerId=${retailerId}&payoutId=${payoutId}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, "sales-data.xlsx");
  } catch (e) {
    console.error("Could not get sales breakdown", e);
  }
}
export async function downloadStoreSalesBreakdown(
  organisationId: OrganisationId,
  interval: DatesRangeValue,
  accessToken: string,
  filterQuery: string,
) {
  try {
    const startDate = format(interval[0]!, "yyyy-MM-dd");
    const endDate = format(interval[1]!, "yyyy-MM-dd");
    const url = `/api/sales/GetStoreSalesDetails?organisationId=${organisationId}&startDate=${startDate}&endDate=${endDate}${filterQuery.length <= 0 ? "" : "&" + filterQuery}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, "sales-data.xlsx");
  } catch (e) {
    console.error("Could not get sales breakdown", e);
  }
}

export async function downloadInvoiceBreakdown(
  organisationId: OrganisationId,
  retailerOrgId: OrganisationId,
  payoutId: string,
  accessToken: string,
) {
  try {
    const url = `/api/payouts/GetPayoutInvoiceBreakdown?organisationId=${organisationId}&payoutId=${payoutId}&retailerOrgId=${retailerOrgId}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, "invoice-breakdown.xlsx");
  } catch (e) {
    console.error("Could not get invoice breakdown", e);
  }
}

export async function downloadInvoicePayouts(
  organisationId: OrganisationId,
  agreementId: string,
  invoiceNo: string,
  accessToken: string,
) {
  try {
    const url = `/api/payouts/GetInvoicePayouts?organisationId=${organisationId}&agreementId=${agreementId}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, `inv-${invoiceNo}-payouts.xlsx`);
  } catch (e) {
    console.error("Could not get invoice payouts", e);
  }
}

export async function downloadDebtBreakdown(
  organisationId: OrganisationId,
  partnerOrgId: OrganisationId,
  paymentInterval: string,
  accessToken: string,
) {
  try {
    const url = `/api/payouts/GetExcelOfDebt?organisationId=${organisationId}&partnerOrgId=${partnerOrgId}&paymentInterval=${paymentInterval}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, `debt-report.xlsx`);
  } catch (e) {
    console.error("Could not get debt report", e);
  }
}

export async function downloadStoreWithdrawalsBreakdown(
  organisationId: OrganisationId,
  date: Date,
  status: WithdrawItemStatus,
  accessToken: string,
) {
  try {
    const formattedDate = format(date, "yyyy-MM-dd");
    const url = `/api/withdrawals/withdrawals-breakdown?organisationId=${organisationId}&date=${formattedDate}&status=${status}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, `${formattedDate}_ ${status}_withdrawals.xlsx`);
  } catch (e) {
    console.error("Could not get withdrawals breakdown", e);
  }
}

export async function downloadWithdrawalBreakdown(
  organisationId: OrganisationId,
  date: Date,
  status: WithdrawItemStatus,
  withdrawalId: string,
  accessToken: string,
) {
  try {
    const formattedDate = format(date, "yyyy-MM-dd");
    const url = `/api/withdrawals/withdrawal-breakdown?organisationId=${organisationId}&withdrawalId=${withdrawalId}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, `${formattedDate}_ ${status}_withdrawals.xlsx`);
  } catch (e) {
    console.error("Could not get withdrawal breakdown", e);
  }
}

export async function downloadIndividualStockBreakdown(
  organisationId: OrganisationId,
  filterQuery: string,
  accessToken: string,
) {
  try {
    const url = `/report/stock/GetExcelOfIndividualStocks?organisationId=${organisationId}&${filterQuery}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    const fileName = "individual-stock-breakdown.xlsx";
    downloadBlob(blob, fileName);
  } catch (e) {
    console.error("Could not get individual stock export", e);
  }
}

export async function downloadArticleRegistry(
  organisationId: OrganisationId,
  supplierId: OrganisationId,
  supplierName: string,
  seasonId: string,
  brand: string,
  accessToken: string,
  body: DownloadArticleRegistryBody,
) {
  try {
    const url = `/api/articles/GetExcelOfArticles?organisationId=${organisationId}&supplierId=${supplierId}&seasonId=${seasonId}&brand=${encodeURIComponent(brand)}`;
    const blob = await basePostQuery(GlobalAppsettings.BaseUrl + url, accessToken, body);
    downloadBlob(blob, `${supplierName} articles.xlsx`);
  } catch (e) {
    console.error("Could not get sales breakdown", e);
  }
}

export async function downloadDeliveryBreakdown(
  organisationId: OrganisationId,
  date: string,
  inventoryId: OrganisationId,
  inventoryName: string,
  accessToken: string,
  filterQuery: string,
) {
  try {
    const url = `/report/stock/GetDeliveriesExcelBreakdown?organisationId=${organisationId}&date=${date}&inventoryId=${inventoryId}${filterQuery.length <= 0 ? "" : "&" + filterQuery}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, `delivery-breakdown.xlsx`);
  } catch (e) {
    console.error("Could not get delivery breakdown", e);
  }
}

export async function downloadCategoryPerformance(
  organisationId: OrganisationId,
  sortDirection: SortDirection,
  filterQuery: string,
  accessToken: string,
  startDate: string,
  endDate: string,
) {
  try {
    const url = `/report/stock/GetExcelOfCategoryPerformance?organisationId=${organisationId}&startDate=${startDate}&endDate=${endDate}&sortDirection=${sortDirection}&${filterQuery}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, `category-performance ${startDate} - ${endDate}.xlsx`);
  } catch (e) {
    console.error("Could not get categoryPerformance", e);
  }
}
export async function downloadBrandPerformance(
  organisationId: OrganisationId,
  sortDirection: SortDirection,
  filterQuery: string,
  accessToken: string,
  startDate: string,
  endDate: string,
) {
  try {
    const url = `/report/stock/GetExcelOfBrandPerformance?organisationId=${organisationId}&startDate=${startDate}&endDate=${endDate}&sortDirection=${sortDirection}&${filterQuery}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, `brand-performance ${startDate} - ${endDate}.xlsx`);
  } catch (e) {
    console.error("Could not get brandPerformance", e);
  }
}

export async function downloadPTPartnerPerformance(
  organisationId: OrganisationId,
  startDate: string,
  endDate: string,
  filterQuery: string,
  accessToken: string,
) {
  try {
    const url = `/report/stock/GetExcelOfPartnerWholesale?organisationId=${organisationId}&startDate=${startDate}&endDate=${endDate}&${filterQuery}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, `partner-performance ${startDate} - ${endDate}.xlsx`);
  } catch (e) {
    console.error("Could not get partnerPerformance", e);
  }
}

export async function downloadRetailerPerformance(
  startDate: string,
  endDate: string,
  organisationId: OrganisationId,
  filterQuery: string,
  accessToken: string,
) {
  try {
    const url = `/api/sales/GetExcelOfRetailerPerformance?organisationId=${organisationId}&startDate=${startDate}&endDate=${endDate}&${filterQuery}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, `retailer-performance ${startDate} - ${endDate}.xlsx`);
  } catch (e) {
    console.error("Could not get retailerPerformance", e);
  }
}

export async function downloadSupplierPerformance(
  startDate: string,
  endDate: string,
  organisationId: OrganisationId,
  filterQuery: string,
  accessToken: string,
) {
  try {
    const url = `/api/sales/GetExcelOfSupplierPerformance?organisationId=${organisationId}&startDate=${startDate}&endDate=${endDate}&${filterQuery}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, `supplier-performance ${startDate} - ${endDate}.xlsx`);
  } catch (e) {
    console.error("Could not get supplierPerformance", e);
  }
}

export async function downloadStylePerformance(
  organisationId: OrganisationId,
  sortDirection: SortDirection,
  filterQuery: string,
  accessToken: string,
  startDate: string,
  endDate: string,
) {
  try {
    const url = `/report/stock/GetExcelOfStylePerformance?organisationId=${organisationId}&startDate=${startDate}&endDate=${endDate}&sortDirection=${sortDirection}&${filterQuery}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, `style-performance ${startDate} - ${endDate}.xlsx`);
  } catch (e) {
    console.error("Could not get articlePerformance", e);
  }
}

export async function downloadColorPerformance(
  organisationId: OrganisationId,
  sortDirection: SortDirection,
  filterQuery: string,
  accessToken: string,
  startDate: string,
  endDate: string,
) {
  try {
    const url = `/report/stock/GetExcelOfColorPerformance?organisationId=${organisationId}&startDate=${startDate}&endDate=${endDate}&sortDirection=${sortDirection}&${filterQuery}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, `color-performance ${startDate} - ${endDate}.xlsx`);
  } catch (e) {
    console.error("Could not get colorPerformance", e);
  }
}

export async function downloadMissingArticles(
  organisationId: OrganisationId,
  dateString: string,
  accessToken: string,
  supplierFilter?: string,
  eanFilter?: string,
) {
  try {
    const url = `/api/stock/GetExcelOfMissingArticles?organisationId=${organisationId}${!supplierFilter ? "" : "&supplierFilter=" + encodeURIComponent(supplierFilter)}${!eanFilter ? "" : "&eanFilter=" + eanFilter}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, `missing-articles-${dateString}.xlsx`);
  } catch (e) {
    console.error("Could not get missing articles", e);
  }
}

export async function downloadArticleActivity(
  organisationId: OrganisationId,
  sortDirection: SortDirection,
  filterQuery: string,
  accessToken: string,
) {
  try {
    const url = `/report/stock/GetExcelOfSupplierArticleActivity?organisationId=${organisationId}&sortDirection=${sortDirection}&${filterQuery}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, `article-activity.xlsx`);
  } catch (e) {
    console.error("Could not get articlePerformance", e);
  }
}

export async function downloadArticlePerStore(
  organisationId: OrganisationId,
  sortDirection: SortDirection,
  filterQuery: string,
  accessToken: string,
) {
  try {
    const url = `/report/stock/GetExcelOfArticlePerStore?organisationId=${organisationId}&sortDirection=${sortDirection}${filterQuery.length <= 0 ? "" : "&" + filterQuery}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, `stock.xlsx`);
  } catch (e) {
    console.error("Could not get stock", e);
  }
}

export async function downloadStorePerformance(
  organisationId: OrganisationId,
  sortDirection: SortDirection,
  filterQuery: string,
  accessToken: string,
  startDate: string,
  endDate: string,
) {
  try {
    const url = `/report/stock/GetExcelOfStorePerformance?organisationId=${organisationId}&startDate=${startDate}&endDate=${endDate}&sortDirection=${sortDirection}&${filterQuery}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, `store-performance ${startDate} - ${endDate}.xlsx`);
  } catch (e) {
    console.error("Could not get storePerformance", e);
  }
}

export async function downloadArticleSizePerformance(
  organisationId: OrganisationId,
  sortDirection: SortDirection,
  filterQuery: string,
  accessToken: string,
  startDate: string,
  endDate: string,
) {
  try {
    const url = `/report/stock/GetExcelOfArticleSizePerformance?organisationId=${organisationId}&startDate=${startDate}&endDate=${endDate}&sortDirection=${sortDirection}&${filterQuery}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, `size-performance ${startDate} - ${endDate}.xlsx`);
  } catch (e) {
    console.error("Could not get size performance", e);
  }
}

export async function downloadSeasonPerformance(
  organisationId: OrganisationId,
  sortDirection: SortDirection,
  filterQuery: string,
  accessToken: string,
  startDate: string,
  endDate: string,
) {
  try {
    const url = `/report/stock/GetExcelOfSeasonPerformance?organisationId=${organisationId}&startDate=${startDate}&endDate=${endDate}&sortDirection=${sortDirection}&${filterQuery}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, `season-performance ${startDate} - ${endDate}.xlsx`);
  } catch (e) {
    console.error("Could not get seasonPerformance", e);
  }
}

export async function downloadVatReport(
  organisationId: OrganisationId,
  startDate: string,
  endDate: string,
  accessToken: string,
) {
  try {
    const url = `/api/sales/GetExcelOfVatReport?organisationId=${organisationId}&startDate=${startDate}&endDate=${endDate}`;
    const blob = await baseQuery(GlobalAppsettings.BaseUrl + url, accessToken);
    downloadBlob(blob, `vat-report ${startDate} - ${endDate}.xlsx`);
  } catch (e) {
    console.error("Could not get vat report", e);
  }
}

async function baseQuery(url: string, accessToken: string) {
  const response = await fetch(url, {
    credentials: "same-origin",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to get sales breakdown " + (await response.text()));
  }

  return await response.blob();
}

async function basePostQuery(url: string, accessToken: string, body: object) {
  const response = await fetch(url, {
    credentials: "same-origin",
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw new Error("Failed to get sales breakdown " + (await response.text()));
  }

  return await response.blob();
}

function downloadBlob(blob: Blob, fileName: string) {
  const url = window.URL.createObjectURL(blob);
  downloadObjectUrl(url, fileName);
  window.URL.revokeObjectURL(url);
}

export function downloadObjectUrl(url: string, fileName: string) {
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  // the filename you want
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export interface DownloadArticleRegistryBody {
  items: DownloadArticleRegistryBodyLineItem[];
}

export interface DownloadArticleRegistryBodyLineItem {
  shorthand: string;
  displayName: string;
  visible: boolean;
}
