import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { ThunkAction } from "../store";
import { integrationsApi } from "../api/IntegrationsApi";
import { clearToken, validateToken } from "./CsrfSlice";
import { OrganisationId } from "../api/models/UserProfile";

export type ShopifyIntegrationState = { type: "pending" } | { type: "completed" } | { type: "error"; message: string };

export type ShopifySlice = {
  state?: ShopifyIntegrationState;
};

const slice = createSlice({
  name: "shopify",
  initialState: {} as ShopifySlice,
  reducers: {
    setState: (state, action: PayloadAction<ShopifyIntegrationState>) => {
      if (action.payload.type === "error") {
        console.error(action.payload.message);
      }
      state.state = action.payload;
    },
  },
});

export default slice.reducer;

export function completeIntegration(
  organisationId: OrganisationId,
  state: string | null,
  code: string | null,
  shop: string | null,
): ThunkAction<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      const rootState = getState();

      const integrationState = rootState.shopify.state;
      const csrfState = rootState.csrf.shopify;

      if (integrationState !== undefined) {
        // Already triggered
        return;
      }

      dispatch(slice.actions.setState({ type: "pending" }));

      if (!code) {
        dispatch(slice.actions.setState({ type: "error", message: "code query parameter is not set" }));
        return;
      }

      if (!shop) {
        dispatch(slice.actions.setState({ type: "error", message: "shop query parameter is not set" }));
        return;
      }

      const validationMessage = validateToken(csrfState, state);
      if (validationMessage) {
        dispatch(
          slice.actions.setState({
            type: "error",
            message: `Failed to validate Shopify CSRF token: ${validationMessage}`,
          }),
        );
        return;
      }

      const result = await dispatch(
        integrationsApi.endpoints.createShopifyConnection.initiate({ organisationId, code, shop }),
      );

      if ("error" in result && result.error) {
        dispatch(slice.actions.setState({ type: "error", message: "There was an error while sending request" }));
        return;
      } else {
        dispatch(slice.actions.setState({ type: "completed" }));
        dispatch(clearToken("shopify"));
      }
    } catch (e) {
      console.log(e);
      dispatch(slice.actions.setState({ type: "error", message: "Unknown error" }));
    }
  };
}
