import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTable, { DataTableSortStatus, TableReportColumn } from "@impulso/common/components/report/DataTable";
import { Language } from "@impulso/common/Language";
import { Loader, LoadingOverlay, ScrollArea } from "@mantine/core";
import { SerializedError } from "@reduxjs/toolkit/dist/createAsyncThunk";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { addDays } from "date-fns/addDays";
import { format } from "date-fns/format";
import { useMemo, useState } from "react";
import { SortDirection } from "src/api/Models";
import {
  useGetArticlePerStoreQuery,
  useGetArticleSizePerformanceQuery,
  useGetBrandPerformanceQuery,
  useGetCategoryPerformanceQuery,
  useGetColorPerformanceQuery,
  useGetSeasonPerformanceQuery,
  useGetStorePerformanceQuery,
  useGetStylePerformanceQuery,
  useGetSupplierArticleActivityQuery,
} from "src/api/ReportApi";
import { useOrganisation } from "src/common/security/UseGlobalSecurity";
import { DashFilter } from "src/pages/new/Dashboard";
import { Organisation } from "../../../api/models/UserProfile";

export interface ArticlePerformance {
  category: string;
  sales: number;
}

export interface ArticleDrawerProps {
  startDate: Date;
  endDate: Date;
  sortDirection: SortDirection;
  filter: DashFilter;
  id: string;
}

export function ArticleDrawer(props: ArticleDrawerProps) {
  const organisation = useOrganisation();
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({
    columnAccessor: "sales",
    direction: props.sortDirection,
  });

  const result = getData(
    props.id,
    sortStatus.direction,
    organisation!,
    props.filter.toQueryString(),
    props.startDate,
    props.endDate,
  );
  return (
    <ArticleDrawerFormat
      formatter={result.formatter}
      categories={result.dataResult}
      itemTitle={result.itemTitle}
      categoryTitle={result.categoryTitle}
      isLoading={result.isLoading}
      sortStatus={sortStatus}
      setSortStatus={setSortStatus}
      salesError={result.salesError}
    />
  );
}

export interface ArticleDrawerFormatProps {
  categoryTitle: string;
  itemTitle: string;
  categories: {
    category: string;
    sales: number;
  }[];
  isLoading: boolean;
  sortStatus: DataTableSortStatus;
  setSortStatus: (status: DataTableSortStatus) => void;
  salesError: FetchBaseQueryError | SerializedError | undefined;
  formatter?: (sales: number) => string;
}

export function ArticleDrawerFormat(props: ArticleDrawerFormatProps) {
  const columnData = useMemo(
    () => createColumns(props.categoryTitle, props.itemTitle, props.formatter),
    [props.categoryTitle, props.formatter, props.itemTitle],
  );

  if (props.salesError !== undefined) {
    return (
      <div className="w-full h-full flex items-center justify-center flex-col">
        <FontAwesomeIcon className="block w-16 h-16 text-brand" icon={faTriangleExclamation} />
        Failed to load data
      </div>
    );
  }
  return (
    <div className="h-full relative p-4">
      <ScrollArea className="overflow-auto h-full" offsetScrollbars>
        <DataTable
          records={props.categories}
          columns={columnData}
          sortStatus={props.sortStatus}
          onSortStatusChange={props.setSortStatus}
        />
      </ScrollArea>
      <LoadingOverlay
        visible={props.isLoading ?? true}
        loader={
          <div className="flex flex-col content-center items-center">
            <Loader />
          </div>
        }
      />
    </div>
  );
}

function createColumns(
  categoryTitle: string,
  itemTitle: string,
  formatter?: (sales: number) => string,
): TableReportColumn<ArticlePerformance>[] {
  return [
    {
      accessor: "category",
      title: categoryTitle,
      sortable: false,
      visibility: "alwaysVisible",
    },
    {
      accessor: "sales",
      title: itemTitle,
      sortable: true,
      visibility: "alwaysVisible",
      render: formatter && (item => formatter(item.sales)),
    },
  ];
}

function getData(
  id: string,
  sortDirection: SortDirection,
  organisation: Organisation,
  filter: string,
  startDate: Date,
  endDate: Date,
): Test {
  const result: any = null;
  const moneyFormatter = (sales: number) => Language.FormatMoney(sales, organisation.defaultCurrency);

  switch (id) {
    case "article": {
      const {
        data,
        isLoading,
        error: salesError,
      } = useGetSupplierArticleActivityQuery({
        organisationId: organisation.id!,
        sortDirection: sortDirection,
        filterQuery: filter,
        limit: -1,
      });
      return {
        formatter: sales => sales + " days",
        categoryTitle: "Style Name/Store",
        itemTitle: "Last Event",
        isLoading,
        salesError: salesError,
        dataResult:
          data?.articles.map(c => ({ category: c.styleName + "/" + c.storeName, sales: c.daysToToday })) ?? [],
      } as Test;
    }
    case "stylePerformance": {
      const {
        data,
        isLoading,
        error: salesError,
      } = useGetStylePerformanceQuery({
        organisationId: organisation.id!,
        startDate: format(startDate, "yyyy-MM-dd"),
        endDate: format(addDays(endDate, 1), "yyyy-MM-dd"),
        sortDirection: sortDirection,
        filterQuery: filter,
        limit: -1,
      });
      return {
        formatter: moneyFormatter,
        categoryTitle: "Style Name",
        itemTitle: "Wholesale Value",
        isLoading,
        salesError: salesError,
        dataResult: data?.styles.map(c => ({ category: c.styleName, sales: c.wholesaleValue })) ?? [],
      } as Test;
    }
    case "brandPerformance": {
      const {
        data,
        isLoading,
        error: stockError,
      } = useGetBrandPerformanceQuery(
        {
          startDate: format(startDate, "yyyy-MM-dd"),
          endDate: format(endDate, "yyyy-MM-dd"),
          organisationId: organisation.id!,
          sortDirection: sortDirection,
          filterQuery: filter,
          limit: -1,
        },
        { skip: !organisation.id },
      );

      return {
        formatter: moneyFormatter,
        categoryTitle: "Brand",
        itemTitle: "Wholesale Value",
        isLoading,
        salesError: stockError,
        dataResult: data?.brands.map(c => ({ category: c.brand, sales: c.wholesaleValue })) ?? [],
      } as Test;
    }
    case "stockPerStore": {
      const {
        data,
        isLoading,
        error: salesError,
      } = useGetArticlePerStoreQuery(
        {
          organisationId: organisation.id!,
          sortDirection: sortDirection,
          filterQuery: filter,
          limit: -1,
        },
        { skip: !organisation.id },
      );

      return {
        formatter: sales => sales + " pcs",
        categoryTitle: "Style Name/Store",
        itemTitle: "Units",
        isLoading,
        salesError: salesError,
        dataResult: data?.stores.map(c => ({ category: c.styleName + "/" + c.storeName, sales: c.quantity })) ?? [],
      } as Test;
    }
    case "seasonPerformance": {
      const {
        data,
        isLoading,
        error: salesError,
      } = useGetSeasonPerformanceQuery({
        organisationId: organisation.id!,
        startDate: format(startDate, "yyyy-MM-dd"),
        endDate: format(addDays(endDate, 1), "yyyy-MM-dd"),
        sortDirection: sortDirection,
        filterQuery: filter,
        limit: -1,
      });

      return {
        formatter: moneyFormatter,
        categoryTitle: "Season",
        itemTitle: "Wholesale Value",
        isLoading,
        salesError: salesError,
        dataResult: data?.seasons.map(c => ({ category: c.seasonTitle, sales: c.wholesaleValue })) ?? [],
      } as Test;
    }
    case "storePerformance": {
      const {
        data,
        isLoading,
        error: salesError,
      } = useGetStorePerformanceQuery(
        {
          organisationId: organisation.id!,
          startDate: format(startDate, "yyyy-MM-dd"),
          endDate: format(addDays(endDate, 1), "yyyy-MM-dd"),
          sortDirection: sortDirection,
          filterQuery: filter,
          limit: -1,
        },
        { skip: !organisation.id },
      );

      return {
        formatter: moneyFormatter,
        categoryTitle: "Store",
        itemTitle: "Wholesale Value",
        isLoading,
        salesError: salesError,
        dataResult: data?.stores.map(c => ({ category: c.storeName, sales: c.wholesaleValue })) ?? [],
      } as Test;
    }
    case "categoryPerformance": {
      const {
        data,
        isLoading,
        error: salesError,
      } = useGetCategoryPerformanceQuery({
        organisationId: organisation.id!,
        startDate: format(startDate, "yyyy-MM-dd"),
        endDate: format(addDays(endDate, 1), "yyyy-MM-dd"),
        sortDirection: sortDirection,
        filterQuery: filter,
        limit: -1,
      });

      return {
        formatter: moneyFormatter,
        categoryTitle: "Category",
        itemTitle: "Wholesale Value",
        isLoading,
        salesError: salesError,
        dataResult: data?.categories.map(c => ({ category: c.category, sales: c.wholesaleValue })) ?? [],
      } as Test;
    }

    case "articleSizePerformance": {
      const {
        data,
        isLoading,
        error: salesError,
      } = useGetArticleSizePerformanceQuery(
        {
          organisationId: organisation.id!,
          startDate: format(startDate, "yyyy-MM-dd"),
          endDate: format(addDays(endDate, 1), "yyyy-MM-dd"),
          sortDirection: sortDirection,
          filterQuery: filter,
          limit: -1,
        },
        { skip: !organisation.id },
      );
      return {
        formatter: moneyFormatter,
        categoryTitle: "Size",
        itemTitle: "Wholesale Value",
        isLoading,
        salesError: salesError,
        dataResult: data?.sizes.map(c => ({ category: c.size ?? "No size", sales: c.wholesaleValue })) ?? [],
      } as Test;
    }
    case "colorPerformance": {
      const {
        data,
        isLoading,
        error: salesError,
      } = useGetColorPerformanceQuery(
        {
          organisationId: organisation.id!,
          startDate: format(startDate, "yyyy-MM-dd"),
          endDate: format(addDays(endDate, 1), "yyyy-MM-dd"),
          sortDirection: sortDirection,
          filterQuery: filter,
          limit: -1,
        },
        { skip: !organisation.id },
      );
      return {
        formatter: moneyFormatter,
        categoryTitle: "Color",
        itemTitle: "Wholesale Value",
        isLoading,
        salesError: salesError,
        dataResult: data?.colors.map(c => ({ category: c.color ?? "No Color", sales: c.wholesaleValue })) ?? [],
      } as Test;
    }
  }

  return result;
}

interface Test {
  categoryTitle: string;
  itemTitle: string;
  isLoading: boolean;
  formatter?: (sales: number) => string;
  salesError: FetchBaseQueryError | SerializedError | undefined;
  dataResult: {
    category: string;
    sales: number;
  }[];
}
