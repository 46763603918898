import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrganisationId } from "../api/models/UserProfile";

export type OrganisationSlice = {
  currentOrganisationId?: OrganisationId;
};

const slice = createSlice({
  name: "organisation",
  initialState: {} as OrganisationSlice,
  reducers: {
    setOrganisation: (state, action: PayloadAction<OrganisationId>) => {
      state.currentOrganisationId = action.payload;
    },
  },
});

export const { setOrganisation } = slice.actions;

export default slice.reducer;
