const { REACT_APP_BASE_URL, REACT_APP_STAGING_BASE_URL, REACT_APP_INTERCOM_ID } = process.env;
const { REACT_APP_DEFAULT_LANGUAGE } = process.env;
const { REACT_APP_AUTH_PROVIDER } = process.env;
const { REACT_APP_VERSION } = process.env;
const { REACT_APP_IMPULSO_VERSION } = process.env;
const { NODE_ENV } = process.env;
const { REACT_APP_RUM_APP_ID, REACT_APP_RUM_CLIENT_TOKEN, REACT_APP_RUM_ENV } = process.env;
const { REACT_APP_FRONTEND_BASE_URL, REACT_APP_FRONTEND_STAGING_BASE_URL } = process.env;

function getBaseUrl() {
  if (document.location.href.includes("-staging")) {
    return REACT_APP_STAGING_BASE_URL;
  }

  return REACT_APP_BASE_URL;
}

function getFrontendBaseUrl() {
  if (document.location.href.includes("-staging")) {
    return REACT_APP_FRONTEND_STAGING_BASE_URL;
  }

  return REACT_APP_FRONTEND_BASE_URL;
}

export class GlobalAppsettings {
  static AppId: string = "impulso-enterprise-portal";

  static ImpulsoVersion: string = REACT_APP_IMPULSO_VERSION?.substring(0, 8) ?? "FAILED TO FETCH";

  static IsDevelopment: boolean = NODE_ENV !== "production";

  static BaseUrl: string = getBaseUrl() ?? "";

  static FrontendBaseUrl: string = getFrontendBaseUrl() ?? "";

  static InterCom: string = REACT_APP_INTERCOM_ID ?? "";

  static AuthProvider: string = REACT_APP_AUTH_PROVIDER ? REACT_APP_AUTH_PROVIDER : "auth0";

  static DefaultLang: string = REACT_APP_DEFAULT_LANGUAGE ? REACT_APP_DEFAULT_LANGUAGE : "en-GB";

  static Version: string = REACT_APP_VERSION ? REACT_APP_VERSION : "1.0";

  static Audience: string = `https://api.portal.impulso.com`;

  static LogoutUri: string = this.IsDevelopment
    ? window.location.protocol + "//" + window.location.host
    : "https://www.impulso.se";

  static RumAppId?: string = REACT_APP_RUM_APP_ID;

  static RumClienToken?: string = REACT_APP_RUM_CLIENT_TOKEN;

  static RumEnv?: string = REACT_APP_RUM_ENV;
}
