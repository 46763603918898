import { ModuleId } from "../api/models/Module";
import { ReactNode } from "react-markdown/lib/ast-to-react";
import Home from "@impulso/common/Icons/Home";
import Paths from "./Paths";
import ProductTracker from "@impulso/common/Icons/ProductTracker";
import PaymentWithdrawals from "@impulso/common/Icons/PaymentWithdrawals";
import Agreements from "@impulso/common/Icons/Agreements";
import Info from "@impulso/common/Icons/Info";
import Gear from "@impulso/common/Icons/Gear";
import { useGetUserQuery } from "../api/UserApi";
import Folder from "@impulso/common/Icons/Folder";
import { OrganisationBindingRole } from "src/api/models/UserProfile";
import { hasModule, organisationTypeFromModule, useOrganisation } from "src/common/security/UseGlobalSecurity";
import { INavigationConfig } from "@impulso/common/configuration/Navigation";

export type Counter = "settings";

export type NavLink = {
  text: string;
  path: string;
  modules?: ModuleId[];
  admin?: boolean;
};

export type PrimaryLink = {
  text: string;
  path: string;
  modules?: ModuleId[];
  icon: ReactNode;
  links?: NavLink[];
  counter?: Counter;
  admin?: boolean;
  external?: boolean;
};

export type NavigationConfig = { id: string; links: PrimaryLink[] }[];

const navigationConfig: (isDev: boolean, organisationType: string) => NavigationConfig = (
  isDev: boolean,
  organisationType: string,
) => {
  return [
    {
      id: "dash",
      links: [
        {
          path: "/",
          text: "navigation.dashboard",
          icon: <Home />,
          modules: ["impulso.store.dashboard", "impulso.supplier.dashboard"],
        },
        {
          path: Paths.productTracker.individualStocks,
          text: "navigation.inventory",
          icon: <ProductTracker />,
          modules: ["impulso.product-tracker.view"],
          links: [
            {
              path: Paths.productTracker.individualStocks,
              text: "navigation.individualStocks",
            },
            {
              path: Paths.productTracker.deliveries,
              text: "navigation.deliveries",
            },
            ...(isDev
              ? [
                  {
                    path: Paths.productTracker.styleCatalog,
                    text: "navigation.styleCatalog",
                  },
                ]
              : []),
          ],
        },
        {
          path: Paths.payment.payoutReport,
          text: "navigation.payment",
          icon: <PaymentWithdrawals />,
          modules: ["impulso.supplier.payments"],
          links: [
            {
              path: Paths.payment.payoutReport,
              text: "navigation.payoutReport",
            },
            {
              path: Paths.payment.clientFundsAccount,
              text: "navigation.clientFundsAccount",
            },
          ],
        },
        {
          path: Paths.withdrawals.withdrawalReport,
          text: "navigation.withdrawals",
          icon: <PaymentWithdrawals />,
          modules: ["impulso.store.withdrawals"],
          links: [
            {
              path: Paths.withdrawals.withdrawalReport,
              text: "navigation.withdrawalsReport",
            },
            {
              path: Paths.withdrawals.vatReport,
              text: "navigation.vatReport",
            },
            {
              path: Paths.withdrawals.clientFundsAccount,
              text: "navigation.clientFundsAccount",
            },
          ],
        },
      ],
    },
    {
      id: "agreements",
      links: [
        {
          path: Paths.agreements.partners,
          text: "navigation.agreements",
          icon: <Agreements />,
          modules: ["impulso.agreements.view"],
        },
      ],
    },
    {
      id: "settings",
      links: [
        {
          path: Paths.articles.articleEvents,
          text: "navigation.articleRegistry",
          icon: <Folder />,
          modules: ["impulso.inventory.articles.view"],
        },
        {
          path: Paths.preferences.index,
          text: "navigation.settings",
          icon: <Gear />,
          counter: "settings",
          links: [
            {
              path: Paths.preferences.users,
              modules: ["impulso.users"],
              text: "navigation.users",
            },
            {
              path: Paths.preferences.stores,
              text: "navigation.storeBindings",
              modules: ["impulso.retailer.front-systems.initiate", "impulso.retailer.fortnox.initiate"],
              admin: true,
            },
            {
              path: Paths.preferences.inventories,
              text: "navigation.inventoryBindings",
              modules: ["impulso.retailer.front-systems.initiate" /*, "impulso.retailer.fortnox.initiate"*/],
              admin: true,
            },
          ],
        },
        {
          path: Paths.preferences.helpdesk,
          text: "navigation.helpdesk",
          icon: <Info />,
          external: true,
        },
      ],
    },
  ];
};

export function useNavigationConfig(modules: ModuleId[], role: OrganisationBindingRole): INavigationConfig {
  const { data: user } = useGetUserQuery();
  const organisation = useOrganisation();
  const type = organisationTypeFromModule(organisation);

  const showLinkPredicate = (requirement: { modules?: ModuleId[]; admin?: boolean }) => {
    const hasModuleReq = !requirement.modules || requirement.modules.some(m => hasModule(modules, m));
    const hasAdminReq = !requirement.admin || role === "Administrator";
    return hasModuleReq && hasAdminReq;
  };

  const config = navigationConfig(user?.user.isDev ?? false, type ?? "");

  return config
    .map(section => ({
      ...section,
      links: section.links.filter(showLinkPredicate).map(link => ({
        ...link,
        links: link.links?.filter(showLinkPredicate),
      })),
    }))
    .filter(s => s.links.length > 0); // Remove empty sections
}
