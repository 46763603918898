import { SimpleBadge } from "@impulso/common/components/Badge";
import { PrimaryButton } from "@impulso/common/components/buttons/PrimaryButton";
import { Page } from "@impulso/common/components/Page";
import { TableReportColumn } from "@impulso/common/components/report/DataTable";
import { TableReport } from "@impulso/common/components/report/TableReport";
import ArrowRight from "@impulso/common/Icons/ArrowRight";
import Plus from "@impulso/common/Icons/Plus";
import FormatDate from "@impulso/common/styling/FormatDate";
import { useDebouncedValue } from "@mantine/hooks";
import { format } from "date-fns/format";
import { parse } from "date-fns/parse";
import Fuse from "fuse.js";
import { ReactNode, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleSearch from "src/common/components/filters/SimpleSearch";
import { InvoiceAgreementResult, ResultItem } from "src/modules/agreements/api/Agreement";
import { useGetAgreementsQuery } from "../../api/AgreementApi";
import { useHasModule, useOrganisation } from "../../common/security/UseGlobalSecurity";
import Paths from "../../configuration/Paths";
import { AgreementLocationState, getAgreementResult } from "./Agreements";

function Click(props: { children: ReactNode }) {
  return <div className="w-full h-full">{props.children}</div>;
}

export function Partners() {
  const organisation = useOrganisation();
  const { data: dataAgreements, isLoading } = useGetAgreementsQuery(
    { organisationId: organisation!.id },
    { skip: !organisation!.id },
  );
  const location = useLocation();
  const locState = location.state as AgreementLocationState | null;
  const [searchString, setSearchString] = useState(locState?.search ?? "");
  const [debouncedSearch] = useDebouncedValue(searchString, 500);

  const agreements = dataAgreements?.map(t => t.agreement) ?? [];

  const agreementResultItems = dataAgreements?.map(a => ({
    ...a,
    ...(a.agreement.type === "Invoice"
      ? {
          invoiceDate: format(
            parse((a as InvoiceAgreementResult).invoiceDate, "yyyy-MM-dd", new Date()),
            "dd MMM yyyy",
          ),
          dueDate: format(parse((a as InvoiceAgreementResult).dueDate, "yyyy-MM-dd", new Date()), "dd MMM yyyy"),
        }
      : {}),
  }));

  const idx = useMemo(() => {
    if (isLoading) {
      return undefined;
    }

    const options = {
      threshold: 0.2,
      keys: ["agreement.retailer", "agreement.supplier"],
      shouldSort: true,
    };

    return new Fuse<ResultItem>(agreementResultItems ?? [], options);
  }, [agreementResultItems, isLoading]);

  const searchResult = useMemo(() => idx?.search(debouncedSearch), [idx, debouncedSearch]);

  const filteredAgreementResult = getAgreementResult(debouncedSearch, agreementResultItems ?? [], searchResult ?? []);
  const filteredAgreements = agreements.filter(a => filteredAgreementResult.some(fa => fa.agreement.id === a.id));

  const groupedByPartner: { [index: string]: PartnersColumn[] } = {};
  for (const data of filteredAgreements) {
    const partnerId = organisation?.isSupplier ? data.retailerId : data.supplierId;
    const item = {
      partnerName: organisation?.isSupplier ? data.retailer : data.supplier,
      brands: data.brands.map(b => b.toUpperCase()),
      agreementTypes: {
        invoices: data.type == "Invoice" ? 1 : 0,
        consignment: data.type == "Split" ? 1 : 0,
        wholesale: data.type == "Wholesale" ? 1 : 0,
        productTracker: data.type == "ProductTracker" ? 1 : 0,
      },
      totalAgreements: 1,
      partnerId: partnerId,
      latestDate: data.startDate,
    };
    if (!groupedByPartner[partnerId]) {
      groupedByPartner[partnerId] = [item];
    } else {
      groupedByPartner[partnerId].push(item);
    }
  }
  const partners = Object.values(groupedByPartner).map(g =>
    g.reduce((prev, curr) => ({
      partnerName: prev.partnerName,
      brands: [...new Set(prev.brands.concat(curr.brands))],
      agreementTypes: {
        invoices: curr.agreementTypes.invoices + prev.agreementTypes.invoices,
        wholesale: curr.agreementTypes.wholesale + prev.agreementTypes.wholesale,
        consignment: curr.agreementTypes.consignment + prev.agreementTypes.consignment,
        productTracker: curr.agreementTypes.productTracker + prev.agreementTypes.productTracker,
      },
      totalAgreements: curr.totalAgreements + prev.totalAgreements,
      partnerId: prev.partnerId,
      latestDate: curr.latestDate > prev.latestDate ? curr.latestDate : prev.latestDate,
    })),
  );

  const canCreateAgreements = useHasModule("impulso.agreements.create");
  const navigate = useNavigate();

  return (
    <Page
      responsive
      titleKey="partners.title"
      hasAccess={true}
      rightAction={
        <div className="flex v-tablet:flex-col flex-row mb-4 gap-4 v-tablet:mt-[34px]">
          <SimpleSearch searchString={searchString} setSearchString={setSearchString} />
          {canCreateAgreements && (
            <PrimaryButton
              onClick={() => navigate(Paths.agreements.create)}
              label="Add new"
              rightIcon={<Plus />}
              extraStyle="w-min v-tablet:w-full"
            ></PrimaryButton>
          )}
        </div>
      }
    >
      <div>
        <TableReport
          columns={getColumnData()}
          rowBody={a => <div></div>}
          rightIcon={<ArrowRight />}
          onClick={value => navigate(`/partners/${value.partnerId}`, { state: { search: debouncedSearch } })}
          rows={partners}
          pageSize={25}
          defaultSort={{ accessor: "partnerName", direction: "desc" }}
          idAccessor={a => a.partnerName}
          isLoading={isLoading}
          loadingText="Loading partners..."
          noContentMessage={"No partners found"}
        />
      </div>
    </Page>
  );

  function getColumnData(): TableReportColumn<PartnersColumn>[] {
    const partnerName: TableReportColumn<PartnersColumn> = {
      accessor: "partnerName",
      sortable: true,
      title: "Partner Name",
      visibility: "alwaysVisible",
      render: a => a.partnerName,
    };
    const brands: TableReportColumn<PartnersColumn> = {
      accessor: "brands",
      sortable: true,
      title: "Brands",
      visibility: "alwaysVisible",
      render: agreement => BrandsGroup(agreement.brands),
    };
    const agreementTypes: TableReportColumn<PartnersColumn> = {
      accessor: "totalAgreements",
      sortable: true,
      title: "Agreement Types",
      visibility: "visible",
      render: agreement => AgreementTypes(agreement.agreementTypes),
    };
    const latestDate: TableReportColumn<PartnersColumn> = {
      accessor: "latestDate",
      sortable: true,
      title: "Latest Date",
      visibility: "visible",
      render: a => FormatDate(a.latestDate),
    };
    return [partnerName, brands, agreementTypes, latestDate].map(col => ({
      ...col,
      render: stuff => <Click>{col.render!(stuff)}</Click>,
    }));
  }

  function AgreementTypes(counter: AgreementCounter) {
    return (
      <div className="flex items-center v-tablet:flex-col v-tablet:items-start gap-2 overflow-x-scroll no-scrollbar">
        {counter.invoices > 0 && (
          <span>
            Invoice
            <b>({counter.invoices})</b>
          </span>
        )}
        {counter.consignment > 0 && (
          <span>
            Consignment
            <b>({counter.consignment})</b>
          </span>
        )}
        {counter.wholesale > 0 && (
          <span>
            Wholesale
            <b>({counter.wholesale})</b>
          </span>
        )}
        {counter.productTracker > 0 && (
          <span>
            Product Tracker
            <b>({counter.productTracker})</b>
          </span>
        )}
      </div>
    );
  }

  function BrandsGroup(brands: string[]) {
    if (brands.length > 2) {
      return (
        <div className="flex items-center v-tablet:flex-col v-tablet:items-start gap-2 overflow-x-scroll no-scrollbar">
          {brands.slice(brands.length - 2).map(brand => (
            <SimpleBadge className="h-6">{brand.toUpperCase()}</SimpleBadge>
          ))}{" "}
          +{brands.slice(2).length}
        </div>
      );
    } else {
      return (
        <div className="flex items-center v-tablet:flex-col v-tablet:items-start gap-2 overflow-x-scroll no-scrollbar">
          {brands.map(brand => (
            <SimpleBadge key={brand} className="h-6">
              {brand.toUpperCase()}
            </SimpleBadge>
          ))}
        </div>
      );
    }
  }
}

interface PartnersColumn {
  partnerName: string;
  brands: string[];
  agreementTypes: AgreementCounter;
  totalAgreements: number;
  partnerId: string;
  latestDate: string;
}

interface AgreementCounter {
  invoices: number;
  consignment: number;
  wholesale: number;
  productTracker: number;
}
